import {
  ForwardRefRenderFunction,
  PropsWithChildren,
  forwardRef,
  useMemo,
} from 'react'

const Component: ForwardRefRenderFunction<HTMLDivElement, PropsWithChildren> = (
  { children },
  ref,
) => {
  const innerHeight = useMemo(() => window.innerHeight, [])

  return (
    <div
      ref={ref}
      className='flex flex-col max-w-[1950px] w-full mx-auto overflow-hidden overflow-y-auto'
      style={{
        height: innerHeight,
      }}
    >
      {children}
    </div>
  )
}

export const PageContainer = forwardRef(Component)
