import { AxiosError, AxiosResponse } from 'axios'
import { api } from '@utils/api'
import { promiseResolver } from '@utils/promiseResolver'

export async function POSTParQForm(
  studentId: string,
  evaluationId: string,
  payload: ParQ,
): Promise<[ParQ | undefined, AxiosError | null]> {
  const [response, error] = await promiseResolver<
    AxiosResponse<ParQ>,
    AxiosError
  >(
    api.post(
      `/students/${studentId}/evaluations/${evaluationId}/anamneses/parq`,
      payload,
    ),
  )

  return [response?.data, error]
}
