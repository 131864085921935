import { useCallback } from 'react'
import { IUpdatePersonalTrainerDTO } from '@services/api/personalTrainers/IUpdatePersonalTrainerDTO'
import { UpdatePersonalTrainerForm } from './components/updatePersonalTrainerForm'
import { PUTUpdatePersonalTrainer } from '@services/api/personalTrainers'
import toast from '@observables/toast'
import { useAuth } from '@hooks/useAuth'

export const PersonalTrainerProfile: React.FC = () => {
  const { updateUserData } = useAuth()

  const handleSubmitForm = useCallback(
    async (data: IUpdatePersonalTrainerDTO) => {
      const [user] = await PUTUpdatePersonalTrainer(data)

      if (user) {
        updateUserData(user)
        toast.insert({
          title: 'Sucesso',
          message: 'Os dados de usuário foram atualizados',
          duration: 4,
        })
      }
    },
    [updateUserData],
  )

  return (
    <div className='bg-white mx-auto p-6 rounded-lg'>
      <UpdatePersonalTrainerForm onSubmit={handleSubmitForm} />
    </div>
  )
}
