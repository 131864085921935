export const SubnavItem: React.FC<SubnavItemProps> = ({
  title,
  isActive = false,
  disabled = false,
  onClick,
}) => {
  return (
    <div
      className={
        (isActive
          ? 'bg-gpa-blue-50 text-gpa-blue-500'
          : 'text-black bg-white hover:bg-gpa-gray-100') +
        ' ' +
        (disabled ? 'opacity-50' : 'opacity-100') +
        ' flex shrink-0 pb-2 text-sm font-semibold py-[6px] px-3 rounded-lg cursor-default'
      }
      onClick={disabled ? undefined : onClick}
    >
      {title}
    </div>
  )
}
