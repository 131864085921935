import { useContext } from 'react'
import { studentEvaluationContext } from '@contexts/studentEvaluationContext'

export function useStudentEvaluation() {
  const context = useContext(studentEvaluationContext)

  if (Object.keys(context).length === 0) {
    throw new Error(
      'useStudentEvaluation must be used within an StudentEvaluationProvider',
    )
  }

  return context
}
