import { useContext } from 'react'

import { StudentCardContext } from '.'

export function useStudentCard() {
  const context = useContext(StudentCardContext)

  if (Object.keys(context).length === 0) {
    throw new Error(
      'useStudentCard must be used within a StudentCard component',
    )
  }

  return context
}
