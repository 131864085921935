/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FiChevronRight } from 'react-icons/fi'
import { Controller, useForm } from 'react-hook-form'

import { generalFormSchema } from './schema'

import { Button } from '@components/Button'
import { Checkbox } from '@components/Checkbox'
import { QuestionFrame } from '@components/QuestionFrame'
import { SelectInput } from '@components/SelectInput'
import { TextInput } from '@components/TextInput'
import { ICreateGeneralDTO } from '@services/api/students/evaluations/anamnesis/general/ICreateGeneralDTO'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback } from 'react'
import {
  exerciseIntensityLevelEnum,
  availableDaysPerWeekEnum,
  availableTimeEnum,
  availableWeekDaysEnum,
  exercisesEnum,
  goalsEnum,
  periodEnum,
  presentPhysicalConditioningEnum,
  exercisesLocaleEnum,
  regularMealsEnum,
  dietEnum,
  efforTestEnum,
  alcoholicDrinksEnum,
  litersPerDayEnum,
  diabetesEnum,
  glicemyLevelEnum,
  cholesterolLevelsEnum,
  triglyceridesLevelsEnum,
  tabagismEnum,
  diseasesEnum,
  medicationEnum,
  jointMuscularDamageEnum,
  pedigreeEnum,
} from './variables'
import { POSTGeneralForm } from '@services/api/students/evaluations/anamnesis/general'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useLayout } from '@hooks/useLayout'

export const GeneralForm: React.FC<GeneralFormProps> = ({
  defaultValues,
  onSubmit,
}) => {
  const { scrollToTop } = useLayout()
  const { student_id, evaluation_id } = useParams<EvaluationRouteParams>()
  const {
    state: { student },
  } = useLocation()
  const navigate = useNavigate()
  const {
    control,
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors, dirtyFields },
  } = useForm<ICreateGeneralDTO>({
    defaultValues,
    mode: 'all',
    resolver: zodResolver(generalFormSchema),
  })

  const handleFormSubmit = useCallback(
    async (data: General) => {
      if (defaultValues && generalFormSchema.safeParse(defaultValues).success) {
        requestAnimationFrame(() => {
          scrollToTop()
        })
        navigate('?subform=parQ', {
          replace: true,
          state: { student },
        })
        return
      }

      if (student_id && evaluation_id) {
        const [general, error] = await POSTGeneralForm(
          student_id,
          evaluation_id,
          data,
        )

        if (general) {
          onSubmit(general)
          scrollToTop()
          navigate('?subform=parQ', {
            // replace: true,
            state: { student },
          })
        } else if (error) {
          console.log(error.response?.data)
        }
      }
    },
    [
      student_id,
      evaluation_id,
      onSubmit,
      navigate,
      defaultValues,
      scrollToTop,
      student,
    ],
  )

  return (
    <div>
      <form
        className='flex flex-col gap-6'
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <QuestionFrame
          title='1) Selecione os objetivos a serem alcançados'
          isValid={
            generalFormSchema
              .pick({ goals: true })
              .safeParse({ goals: getValues('goals') }).success
          }
        >
          <div className='grid grid-cols-[repeat(auto-fit,_minmax(150px,_230px))] justify-between gap-4'>
            {goalsEnum.map((item, index) => (
              <Checkbox
                key={`goal-${index}`}
                {...register('goals')}
                label={item}
                value={item}
                error={!!errors.goals?.message}
              />
            ))}
          </div>
          <span className='flex text-xs text-gpa-red'>
            {errors.goals?.message}
          </span>
        </QuestionFrame>

        <QuestionFrame
          title='2) Condicionamento físico atual'
          isValid={
            generalFormSchema
              .pick({ presentPhysicalConditioning: true })
              .safeParse({
                presentPhysicalConditioning: getValues(
                  'presentPhysicalConditioning',
                ),
              }).success
          }
        >
          <Controller
            control={control}
            name='presentPhysicalConditioning'
            render={({ field }) => (
              <SelectInput
                label='Estado atual'
                value={field.value}
                options={presentPhysicalConditioningEnum.map((item) => ({
                  label: item,
                  value: item,
                }))}
                onChange={field.onChange}
                error={errors.presentPhysicalConditioning?.message}
              />
            )}
          />
        </QuestionFrame>

        <QuestionFrame
          title='3) Há quanto tempo?'
          isValid={
            generalFormSchema
              .pick({ physicalConditioningTimePeriod: true })
              .safeParse({
                physicalConditioningTimePeriod: getValues(
                  'physicalConditioningTimePeriod',
                ),
              }).success
          }
        >
          <div className='flex gap-4 flex-wrap'>
            <Controller
              control={control}
              name='physicalConditioningTimePeriod.period'
              render={({ field }) => (
                <SelectInput
                  label='tempo'
                  placeholder='Dia(s)'
                  options={periodEnum.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                  onChange={field.onChange}
                  value={field.value}
                  error={errors.physicalConditioningTimePeriod?.period?.message}
                />
              )}
            />
            <TextInput
              {...register('physicalConditioningTimePeriod.quantity', {
                valueAsNumber: true,
              })}
              label='Quantidade'
              placeholder='0'
              type='number'
              error={errors.physicalConditioningTimePeriod?.quantity?.message}
            />
          </div>
        </QuestionFrame>

        <QuestionFrame
          title='4) Quantos dias disponíveis por semana?'
          isValid={
            generalFormSchema.pick({ availableDaysPerWeek: true }).safeParse({
              availableDaysPerWeek: getValues('availableDaysPerWeek'),
            }).success
          }
        >
          <Controller
            control={control}
            name='availableDaysPerWeek'
            render={({ field }) => (
              <SelectInput
                label='Dias'
                value={String(field.value)}
                onChange={(value) => field.onChange(Number(value))}
                options={availableDaysPerWeekEnum.map((item) => ({
                  label: item,
                  value: item,
                }))}
                error={errors.availableDaysPerWeek?.message}
              />
            )}
          />
        </QuestionFrame>

        <QuestionFrame
          title='5) Tempo disponível?'
          isValid={
            generalFormSchema.pick({ availableTime: true }).safeParse({
              availableTime: getValues('availableTime'),
            }).success
          }
        >
          <Controller
            control={control}
            name='availableTime'
            render={({ field }) => (
              <SelectInput
                label='Tempo'
                value={field.value}
                onChange={field.onChange}
                options={availableTimeEnum.map((item) => ({
                  label: item,
                  value: item,
                }))}
                error={errors.availableTime?.message}
              />
            )}
          />
        </QuestionFrame>

        <QuestionFrame
          title='6) Marque os dias disponíveis para prática de atividade físicas'
          isValid={
            generalFormSchema
              .pick({ availableWeekDaysForPhysical: true })
              .safeParse({
                availableWeekDaysForPhysical: getValues(
                  'availableWeekDaysForPhysical',
                ),
              }).success
          }
        >
          <div className='grid grid-cols-[repeat(auto-fit,_minmax(150px,_230px))] justify-between gap-4'>
            {availableWeekDaysEnum.map((item, index) => (
              <Checkbox
                key={`availableWeekDays-${index}`}
                {...register('availableWeekDaysForPhysical')}
                label={item}
                value={item}
                error={!!errors.availableWeekDaysForPhysical?.message}
              />
            ))}
          </div>
          <span className='flex text-xs text-gpa-red'>
            {errors.availableWeekDaysForPhysical?.message}
          </span>
        </QuestionFrame>

        <QuestionFrame
          title='7) Marque os exercícios físicos realizados atualmente'
          isValid={
            generalFormSchema.pick({ currentExercises: true }).safeParse({
              currentExercises: getValues('currentExercises'),
            }).success
          }
        >
          <div className='grid grid-cols-[repeat(auto-fit,_minmax(150px,_230px))] justify-between gap-4'>
            {exercisesEnum.map((item, index) => (
              <Checkbox
                key={`exercises-${index}`}
                {...register('currentExercises.exercises')}
                label={item}
                value={item}
                //@ts-ignore
                error={
                  (errors.currentExercises ||
                    !!dirtyFields['currentExercises']) &&
                  (!watch('currentExercises.exercises') ||
                    watch('currentExercises.exercises')?.length === 0) &&
                  watch('currentExercises.other')?.length === 0
                }
              />
            ))}
          </div>

          <TextInput
            {...register('currentExercises.other')}
            label='Outras'
            error={
              (errors.currentExercises || !!dirtyFields['currentExercises']) &&
              (!watch('currentExercises.exercises') ||
                watch('currentExercises.exercises')?.length === 0) &&
              watch('currentExercises.other')?.length === 0
                ? 'Ao menos um valor deve ser preenchido'
                : ''
            }
          />
        </QuestionFrame>

        <QuestionFrame
          title='8) Intensidade das (atividades acima)'
          isValid={
            generalFormSchema.pick({ exercisesIntensity: true }).safeParse({
              exercisesIntensity: getValues('exercisesIntensity'),
            }).success
          }
        >
          <Controller
            control={control}
            name='exercisesIntensity'
            render={({ field }) => (
              <SelectInput
                error={errors.exercisesIntensity?.message}
                value={field.value}
                onChange={field.onChange}
                options={exerciseIntensityLevelEnum.map((item) => ({
                  label: item,
                  value: item,
                }))}
              />
            )}
          />
        </QuestionFrame>

        <QuestionFrame
          title='9) Onde prefere fazer atividade física?'
          isValid={
            generalFormSchema.pick({ exercisesLocale: true }).safeParse({
              exercisesLocale: getValues('exercisesLocale'),
            }).success
          }
        >
          <Controller
            control={control}
            name='exercisesLocale'
            render={({ field }) => (
              <SelectInput
                value={field.value}
                onChange={field.onChange}
                options={exercisesLocaleEnum.map((item) => ({
                  label: item,
                  value: item,
                }))}
                error={errors.exercisesLocale?.message}
              />
            )}
          />
        </QuestionFrame>

        <QuestionFrame
          title='10) Refeições normalmente realizadas no dia'
          isValid={
            generalFormSchema.pick({ regularMeals: true }).safeParse({
              regularMeals: getValues('regularMeals'),
            }).success
          }
        >
          <div className='grid grid-cols-[repeat(auto-fit,_minmax(150px,_230px))] justify-between gap-4'>
            {regularMealsEnum.map((item, index) => (
              <Checkbox
                key={`regularMeals-${index}`}
                {...register('regularMeals')}
                label={item}
                value={item}
                error={!!errors.regularMeals?.message}
              />
            ))}
          </div>
          <span className='flex text-xs text-gpa-red'>
            {errors.regularMeals?.message}
          </span>
        </QuestionFrame>

        <QuestionFrame
          title='11) Algum tipo de dieta?'
          isValid={
            generalFormSchema.pick({ diet: true }).safeParse({
              diet: getValues('diet'),
            }).success
          }
        >
          <Controller
            control={control}
            name='diet'
            render={({ field }) => (
              <SelectInput
                value={field.value}
                onChange={field.onChange}
                options={dietEnum.map((item) => ({ label: item, value: item }))}
                error={errors.diet?.message}
              />
            )}
          />
        </QuestionFrame>

        <QuestionFrame
          title='12) Suplementos alimentares em uso'
          isValid={
            generalFormSchema.pick({ suplements: true }).safeParse({
              suplements: getValues('suplements'),
            }).success
          }
        >
          <TextInput
            {...register('suplements')}
            error={errors.suplements?.message}
          />
        </QuestionFrame>

        <QuestionFrame
          title='13) Já fez teste de esforço?'
          isValid={
            generalFormSchema.pick({ effortTest: true }).safeParse({
              effortTest: getValues('effortTest'),
            }).success
          }
        >
          <Controller
            control={control}
            name='effortTest'
            render={({ field }) => (
              <SelectInput
                value={field.value}
                onChange={field.onChange}
                options={efforTestEnum.map((item) => ({
                  label: item,
                  value: item,
                }))}
                error={errors.effortTest?.message}
              />
            )}
          />
        </QuestionFrame>

        <QuestionFrame
          title='14) Bebidas alcóolicas (Fonte AUDIT)'
          isValid={
            generalFormSchema.pick({ alcoholicDrinks: true }).safeParse({
              alcoholicDrinks: getValues('alcoholicDrinks'),
            }).success
          }
        >
          <Controller
            control={control}
            name='alcoholicDrinks'
            render={({ field }) => (
              <SelectInput
                value={field.value}
                onChange={field.onChange}
                options={alcoholicDrinksEnum.map((item) => ({
                  label: item,
                  value: item,
                }))}
                error={errors.alcoholicDrinks?.message}
              />
            )}
          />
        </QuestionFrame>

        <QuestionFrame
          title='15) Ingere líquidos com frequência? (2 litros é o recomendado pelo "Guia alimentar para população brasileira" - Min. da Saúde)'
          isValid={
            generalFormSchema.pick({ litersPerDay: true }).safeParse({
              litersPerDay: getValues('litersPerDay'),
            }).success
          }
        >
          <Controller
            control={control}
            name='litersPerDay'
            render={({ field }) => (
              <SelectInput
                value={field.value}
                onChange={field.onChange}
                options={litersPerDayEnum.map((item) => ({
                  label: item,
                  value: item,
                }))}
                error={errors.litersPerDay?.message}
              />
            )}
          />
        </QuestionFrame>

        <QuestionFrame
          title='16) Diabetes?'
          isValid={
            generalFormSchema.pick({ diabetes: true }).safeParse({
              diabetes: getValues('diabetes'),
            }).success
          }
        >
          <Controller
            control={control}
            name='diabetes'
            render={({ field }) => (
              <SelectInput
                value={field.value}
                onChange={field.onChange}
                options={diabetesEnum.map((item) => ({
                  label: item,
                  value: item,
                }))}
                error={errors.diabetes?.message}
              />
            )}
          />
        </QuestionFrame>

        <QuestionFrame
          title='17) Nível de glicemia em jejum (Fonte: Laboratório Delboni Auriemo)'
          isValid={
            generalFormSchema.pick({ glicemyLevel: true }).safeParse({
              glicemyLevel: getValues('glicemyLevel'),
            }).success
          }
        >
          <Controller
            control={control}
            name='glicemyLevel'
            render={({ field }) => (
              <SelectInput
                value={field.value}
                onChange={field.onChange}
                options={glicemyLevelEnum.map((item) => ({
                  label: item,
                  value: item,
                }))}
                error={errors.glicemyLevel?.message}
              />
            )}
          />
        </QuestionFrame>

        <QuestionFrame
          title='18) Nível de colesterol total (Fonte Laboratório Delboni Auriemo)'
          isValid={
            generalFormSchema.pick({ cholesterolLevels: true }).safeParse({
              cholesterolLevels: getValues('cholesterolLevels'),
            }).success
          }
        >
          <Controller
            control={control}
            name='cholesterolLevels'
            render={({ field }) => (
              <SelectInput
                value={field.value}
                onChange={field.onChange}
                options={cholesterolLevelsEnum.map((item) => ({
                  value: item,
                  label: item,
                }))}
                error={errors.cholesterolLevels?.message}
              />
            )}
          />
        </QuestionFrame>

        <QuestionFrame
          title='19) Nível de triglicerídeos (Fonte Laboratório Delboni Auriemo)'
          isValid={
            generalFormSchema.pick({ triglyceridesLevels: true }).safeParse({
              triglyceridesLevels: getValues('triglyceridesLevels'),
            }).success
          }
        >
          <Controller
            control={control}
            name='triglyceridesLevels'
            render={({ field }) => (
              <SelectInput
                value={field.value}
                onChange={field.onChange}
                options={triglyceridesLevelsEnum.map((item) => ({
                  label: item,
                  value: item,
                }))}
                error={errors.triglyceridesLevels?.message}
              />
            )}
          />
        </QuestionFrame>

        <QuestionFrame
          title='20) Tabagismo'
          isValid={
            generalFormSchema.pick({ tabagism: true }).safeParse({
              tabagism: getValues('tabagism'),
            }).success
          }
        >
          <Controller
            control={control}
            name='tabagism'
            render={({ field }) => (
              <SelectInput
                value={field.value}
                onChange={field.onChange}
                options={tabagismEnum.map((item) => ({
                  label: item,
                  value: item,
                }))}
                error={errors.tabagism?.message}
              />
            )}
          />
        </QuestionFrame>

        <QuestionFrame
          title='21) Doenças e queixas'
          isValid={
            generalFormSchema.pick({ diseases: true }).safeParse({
              diseases: getValues('diseases'),
            }).success
          }
        >
          <div className='grid grid-cols-[repeat(auto-fit,_minmax(150px,_230px))] justify-between gap-4'>
            {diseasesEnum.map((item, index) => (
              <Checkbox
                key={`diseases-${index}`}
                {...register('diseases.diseases')}
                label={item}
                value={item}
                //@ts-ignore
                error={
                  (errors.diseases || !!dirtyFields['diseases']) &&
                  (!watch('diseases.diseases') ||
                    watch('diseases.diseases')?.length === 0) &&
                  watch('diseases.other')?.length === 0
                }
              />
            ))}
          </div>

          <TextInput
            {...register('diseases.other')}
            label='Outras doenças e queixas'
            error={
              (errors.diseases || !!dirtyFields['diseases']) &&
              (!watch('diseases.diseases') ||
                watch('diseases.diseases')?.length === 0) &&
              watch('diseases.other')?.length === 0
                ? 'Ao menos um valor deve ser preenchido'
                : ''
            }
          />
        </QuestionFrame>

        <QuestionFrame
          title='22) Medicamentos utilizados regularmente'
          isValid={
            generalFormSchema.pick({ regularMedications: true }).safeParse({
              regularMedications: getValues('regularMedications'),
            }).success
          }
        >
          <div className='grid grid-cols-[repeat(auto-fit,_minmax(150px,_230px))] justify-between gap-4'>
            {medicationEnum.map((item, index) => (
              <Checkbox
                key={`regularMedications-${index}`}
                {...register('regularMedications.medications')}
                label={item}
                value={item}
                //@ts-ignore
                error={
                  (errors.regularMedications ||
                    !!dirtyFields['regularMedications']) &&
                  (!watch('regularMedications.medications') ||
                    watch('regularMedications.medications')?.length === 0) &&
                  watch('regularMedications.other')?.length === 0
                }
              />
            ))}
          </div>

          <TextInput
            {...register('regularMedications.other')}
            label='Outro medicamento utilizado'
            error={
              (errors.regularMedications ||
                !!dirtyFields['regularMedications']) &&
              (!watch('regularMedications.medications') ||
                watch('regularMedications.medications')?.length === 0) &&
              watch('regularMedications.other')?.length === 0
                ? 'Ao menos um valor deve ser preenchido'
                : ''
            }
          />
        </QuestionFrame>

        <QuestionFrame
          title='23) Lesões articulares ou musculares'
          isValid={
            generalFormSchema.pick({ jointMuscularDamage: true }).safeParse({
              jointMuscularDamage: getValues('jointMuscularDamage'),
            }).success
          }
        >
          <div className='grid grid-cols-[repeat(auto-fit,_minmax(150px,_230px))] justify-between gap-4'>
            {jointMuscularDamageEnum.map((item, index) => (
              <Checkbox
                key={`muscularDamage-${index}`}
                {...register('jointMuscularDamage')}
                label={item}
                value={item}
                error={!!errors.jointMuscularDamage?.message}
              />
            ))}
          </div>
          <span className='flex text-xs text-gpa-red'>
            {errors.jointMuscularDamage?.message}
          </span>
        </QuestionFrame>

        <QuestionFrame
          title='24) Histórico familiar'
          isValid={
            generalFormSchema.pick({ pedigree: true }).safeParse({
              pedigree: getValues('pedigree'),
            }).success
          }
        >
          <div className='grid grid-cols-[repeat(auto-fit,_minmax(150px,_230px))] justify-between gap-4'>
            {pedigreeEnum.map((item, index) => (
              <Checkbox
                key={`pedigree-${index}`}
                {...register('pedigree')}
                label={item}
                value={item}
                error={!!errors.pedigree?.message}
              />
            ))}
          </div>
          <span className='flex text-xs text-gpa-red'>
            {errors.pedigree?.message}
          </span>
        </QuestionFrame>

        <QuestionFrame
          title='25) Comentários'
          isValid={
            generalFormSchema.pick({ commentary: true }).safeParse({
              commentary: getValues('commentary'),
            }).success
          }
        >
          <TextInput
            label='Algo que deva ser levado em consideração'
            {...register('commentary')}
            error={errors.commentary?.message}
          />
        </QuestionFrame>

        <div className='flex justify-end'>
          <Button>
            Avançar <FiChevronRight size={24} />
          </Button>
        </div>
      </form>
    </div>
  )
}
