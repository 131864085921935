import { AnimationPlaybackControls, motion, useAnimate } from 'framer-motion'
import { useCallback, useRef } from 'react'
import { FiXCircle } from 'react-icons/fi'

export const Toast: React.FC<ToastProps> = ({ data, onTimerEnd }) => {
  const [timeAnimationScope, timerAnimation] = useAnimate()
  const timerAnimationControlsRef = useRef<AnimationPlaybackControls>()

  const handleStartTimer = useCallback(async () => {
    timerAnimationControlsRef.current = timerAnimation(
      timeAnimationScope.current,
      {
        scaleX: 0,
        originX: 0,
      },
      {
        duration: data.duration,
        onComplete() {
          onTimerEnd && onTimerEnd(data.id)
        },
      },
    )

    timerAnimationControlsRef.current.play()
  }, [timerAnimation, data, onTimerEnd, timeAnimationScope])

  return (
    <div className='relative flex flex-col'>
      <motion.div
        initial={{ transform: 'translate3d(100%, 0, 0)' }}
        animate={{ transform: 'translate3d(0%, 0, 0)' }}
        exit={{ transform: 'translate3d(120%, 0, 0)' }}
        transition={{
          duration: 0.4,
          ease: [0, 0.71, 0.2, 1],
        }}
        onMouseOver={() => {
          timerAnimationControlsRef.current?.pause()
        }}
        onMouseLeave={() => {
          timerAnimationControlsRef.current?.play()
        }}
        onAnimationComplete={handleStartTimer}
        className='flex flex-col w-full max-w-[550px] min-w-[300px] rounded-md text-white bg-gpa-blue-500 overflow-hidden'
      >
        <div ref={timeAnimationScope} className='h-1 bg-white w-full' />
        <div className='flex flex-1 gap-1.5 p-1.5 '>
          <FiXCircle size={24} className='shrink-0 mt-1' />
          <div className='flex flex-col gap-1.5'>
            <span className='text-lg'>{data.title}</span>
            <span>{data.message}</span>
          </div>
        </div>
      </motion.div>
    </div>
  )
}
