import { AppRouter } from './routes'
import { AuthProvider } from '@contexts/authContext'
import { AuthGate } from '@components/AuthGate'
import { Toaster } from '@components/Toaster'

function App() {
  return (
    <AuthProvider>
      <AuthGate>
        <AppRouter />
      </AuthGate>
      <Toaster />
    </AuthProvider>
  )
}

export default App
