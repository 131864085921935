import { POSTLogin } from '@services/api/authentication'
import { api } from '@utils/api'
import { storageAuthKey } from '@utils/storageKeys'
import { PropsWithChildren, createContext, useCallback, useState } from 'react'

const authContext = createContext<AuthContextData>({} as AuthContextData)

const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [authData, setAuthData] = useState<AuthenticationDTO | undefined>(
    () => {
      const storageData = localStorage.getItem(storageAuthKey)
      if (storageData) {
        const data = JSON.parse(storageData)
        api.defaults.headers.authorization = `Bearer ${data.token}`
        return data
      }
      return undefined
    },
  )

  const signIn = useCallback(async (payload: LoginFormData) => {
    const data = await POSTLogin({
      documentNumber: payload.documentNumber,
      password: payload.password,
    })

    if (!data) {
      console.warn('no data!')
      return
    }

    api.defaults.headers.authorization = `Bearer ${data.token}`

    if (payload.shouldRemember) {
      localStorage.setItem(storageAuthKey, JSON.stringify(data))
    }

    setAuthData(data)
  }, [])

  const signOut = useCallback(() => {
    localStorage.clear()
    delete api.defaults.headers.authorization
    setAuthData(undefined)
  }, [])

  const updateUserData = useCallback((updatedUser: User) => {
    const storageData = localStorage.getItem(storageAuthKey)
    if (storageData) {
      const data: AuthenticationDTO = JSON.parse(storageData)

      const updatedData: AuthenticationDTO = {
        token: data.token,
        user: updatedUser,
      }

      localStorage.setItem(storageAuthKey, JSON.stringify(updatedData))
      setAuthData(updatedData)
    }
  }, [])

  return (
    <authContext.Provider
      value={{
        signIn,
        signOut,
        updateUserData,
        authData,
      }}
    >
      {children}
    </authContext.Provider>
  )
}

export { authContext, AuthProvider }
