import { useCallback, useRef } from 'react'
import PicturePlaceholder from '@assets/userPlaceholder.webp'

export const StudentTag: React.FC<StudentTagProps> = ({
  name,
  email,
  picture,
  phoneNumber,
}) => {
  const imgRef = useRef<HTMLImageElement>(null)

  const handleProfilePicError = useCallback(() => {
    if (imgRef.current) {
      imgRef.current.src = PicturePlaceholder
    }
  }, [])

  return (
    <div className='flex gap-3 items-center'>
      <div className='h-14 w-14 rounded-full overflow-hidden'>
        <img
          ref={imgRef}
          src={picture || PicturePlaceholder}
          alt='face'
          loading='eager'
          className='h-full w-full object-contain'
          onError={handleProfilePicError}
        />
      </div>
      <div>
        <p className='text-base'>{name}</p>
        <p className='text-xs'>
          {email} | {phoneNumber}
        </p>
      </div>
    </div>
  )
}
