import { useCallback, useEffect, useState } from 'react'
import { AnimatePresence } from 'framer-motion'

import { Toast } from './components/Toast'
import toastObserver from '@observables/toast'

export const Toaster: React.FC = () => {
  const [toasts, setToasts] = useState<ToastDTO[]>([])

  const handleRemoveToast = useCallback((id?: number) => {
    setToasts((state) => state.filter((toast) => toast.id !== id))
  }, [])

  const handleInsertToast = useCallback((toast: ToastDTO) => {
    setToasts((state) => [...state, toast])
  }, [])

  useEffect(() => {
    toastObserver.subscribe({ id: 'toaster', callback: handleInsertToast })

    return () => {
      toastObserver.unsubscribe('toaster')
    }
  }, [handleInsertToast])

  return (
    <div
      className='fixed z-50 right-0 top-0 flex flex-col gap-3 p-3 overflow-hidden overflow-y-auto  bg-opacity-25'
      style={{ maxHeight: window.innerHeight }}
    >
      <AnimatePresence>
        {toasts.map((toast) => (
          <Toast
            key={`toast-${toast.id}`}
            data={toast}
            onTimerEnd={handleRemoveToast}
          />
        ))}
      </AnimatePresence>
    </div>
  )
}
