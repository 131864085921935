import { Outlet, Route, Routes } from 'react-router-dom'
import { PersonalTrainerAreaLayout } from '@layout/PersonalTrainerAreaLayout'
import { PersonalTrainerHome } from '@pages/authenticated/personalTrainerHome'
import { personalTrainerProfileRoute, studentEvaluationsRoute } from './routes'
import { StudentEvaluation } from '@pages/authenticated/studentEvaluation'
import { StudentEvaluationProvider } from '@contexts/studentEvaluationContext'
import { PersonalTrainerProfile } from '@pages/authenticated/personalTrainerProfile'

export const AuthenticatedRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        element={
          <PersonalTrainerAreaLayout>
            <Outlet />
          </PersonalTrainerAreaLayout>
        }
      >
        <Route path='/' element={<PersonalTrainerHome />} />
        <Route
          path={`${studentEvaluationsRoute}/*`}
          element={
            <StudentEvaluationProvider>
              <StudentEvaluation />
            </StudentEvaluationProvider>
          }
        />
        <Route
          path={personalTrainerProfileRoute}
          element={<PersonalTrainerProfile />}
        />
      </Route>
      <Route path='*' element={<h1>404</h1>} />
    </Routes>
  )
}
