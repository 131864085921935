import { Button } from '@components/Button'
import { QuestionFrame } from '@components/QuestionFrame'
import { RadioButton } from '@components/RadioButton'
import { FiChevronRight } from 'react-icons/fi'

export const StressForm: React.FC = () => {
  return (
    <div>
      <form className='flex flex-col gap-6'>
        <h3 className='font-semibold'>Você tem sofrido algum desses males?</h3>
        <div className='grid grid-cols-2 gap-6'>
          <QuestionFrame title='1) Falta de apetite' isValid={false}>
            <div className='flex gap-4'>
              <RadioButton name='stress' label='Sim'></RadioButton>
              <RadioButton name='stress' label='Não'></RadioButton>
            </div>
          </QuestionFrame>
          <QuestionFrame title='2) F.C. elevada em repouso' isValid={false}>
            <div className='flex gap-4'>
              <RadioButton name='stress' label='Sim'></RadioButton>
              <RadioButton name='stress' label='Não'></RadioButton>
            </div>
          </QuestionFrame>
          <QuestionFrame title='3) Pressão arterial alta' isValid={false}>
            <div className='flex gap-4'>
              <RadioButton name='stress' label='Sim'></RadioButton>
              <RadioButton name='stress' label='Não'></RadioButton>
            </div>
          </QuestionFrame>
          <QuestionFrame
            title='4) Disfunções gastro-intestinais'
            isValid={false}
          >
            <div className='flex gap-4'>
              <RadioButton name='stress' label='Sim'></RadioButton>
              <RadioButton name='stress' label='Não'></RadioButton>
            </div>
          </QuestionFrame>
          <QuestionFrame
            title='5) Lesões musculares constantes'
            isValid={false}
          >
            <div className='flex gap-4'>
              <RadioButton name='stress' label='Sim'></RadioButton>
              <RadioButton name='stress' label='Não'></RadioButton>
            </div>
          </QuestionFrame>
          <QuestionFrame title='6) Falta de concentração' isValid={false}>
            <div className='flex gap-4'>
              <RadioButton name='stress' label='Sim'></RadioButton>
              <RadioButton name='stress' label='Não'></RadioButton>
            </div>
          </QuestionFrame>
          <QuestionFrame title='7) Insônia' isValid={false}>
            <div className='flex gap-4'>
              <RadioButton name='stress' label='Sim'></RadioButton>
              <RadioButton name='stress' label='Não'></RadioButton>
            </div>
          </QuestionFrame>
          <QuestionFrame title='8) Diminuição da libido' isValid={false}>
            <div className='flex gap-4'>
              <RadioButton name='stress' label='Sim'></RadioButton>
              <RadioButton name='stress' label='Não'></RadioButton>
            </div>
          </QuestionFrame>
          <QuestionFrame title='9) Irritabilidade' isValid={false}>
            <div className='flex gap-4'>
              <RadioButton name='stress' label='Sim'></RadioButton>
              <RadioButton name='stress' label='Não'></RadioButton>
            </div>
          </QuestionFrame>
        </div>
        <div className='flex justify-end'>
          <Button>
            Avançar <FiChevronRight size={24} />
          </Button>
        </div>
      </form>
    </div>
  )
}
