import { createContext, useCallback, useRef } from 'react'
import PlaceholderImg from '@assets/userPlaceholder.webp'
import { Link } from 'react-router-dom'
import { studentEvaluationsRoute } from '@routes/routes'
import { UpdateStudent } from './components/UpdateStudent'
import { NewEvaluationButton } from './components/NewEvaluationButton'
import { EvaluationsList } from './components/EvaluationsList'

const StudentCardContext = createContext<StudentCardProps>(
  {} as StudentCardProps,
)

const StudentCard: React.FC<StudentCardProps> = ({ student }) => {
  const imgRef = useRef<HTMLImageElement>(null)

  const handleProfilePicError = useCallback(() => {
    if (imgRef.current) {
      imgRef.current.src = PlaceholderImg
    }
  }, [])

  return (
    <StudentCardContext.Provider value={{ student }}>
      <div className='flex flex-col items-center h-[301px] p-4 rounded-2xl bg-white w-full gap-3'>
        <div className='flex bg-white h-14 w-14 rounded-full overflow-hidden'>
          <img
            ref={imgRef}
            src={null || PlaceholderImg}
            alt='face'
            className='w-full h-full object-contain'
            onError={handleProfilePicError}
          />
        </div>
        <div className='flex flex-1 items-center justify-end gap-2 flex-col'>
          <h3 className='font-bold'>{student.name}</h3>
          {student.studentEvaluations.length ? null : (
            <div className='flex flex-1 items-center'>
              <p className='mt-10'>Nenhuma avaliação ainda</p>
            </div>
          )}
          {student.studentEvaluations.length ? (
            <small className='mb-auto'>Últimas avaliações</small>
          ) : null}
          <div className='flex flex-col flex-1 justify-between'>
            {student.studentEvaluations
              .map((evaluation) => (
                <Link
                  key={evaluation.id}
                  to={studentEvaluationsRoute
                    .replace(':student_id', student.id)
                    .replace(':evaluation_id', evaluation.id)}
                  className='text-gpa-blue-500'
                  state={{ student }}
                >
                  {new Date(evaluation.createdAt).toLocaleDateString()}
                </Link>
              ))
              .splice(0, 2)}
            {student.studentEvaluations.length ? <EvaluationsList /> : null}
          </div>
          <div className='flex gap-2 flex-wrap items-end'>
            <UpdateStudent />
            <NewEvaluationButton />
          </div>
        </div>
      </div>
    </StudentCardContext.Provider>
  )
}

export { StudentCardContext, StudentCard }
