import {
  PropsWithChildren,
  createContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'
import { Modal } from '@components/Modal'
import { GETAnamnesisRecord } from '@services/api/students/evaluations/anamnesis'
import { debounce } from '@utils/debounce'
import { useStudentEvaluation } from '@hooks/useStudentEvaluation'

const studentAnamnesisContext = createContext<StudentAnamnesisContextData>(
  {} as StudentAnamnesisContextData,
)

const StudentAnamnesisProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { updateEvaluation, evaluation } = useStudentEvaluation()
  const {
    state: { student },
  }: { state: { student: Student } } = useLocation()

  const [loading, setLoading] = useState<boolean>(false)
  const [anamnesis, setAnamnesis] = useState<Anamnesis>()

  // Upgrade to query hook (react-query?)
  const debouncer = useMemo(() => debounce(), [])
  const queryDone = useRef<boolean>(false)

  useEffect(() => {
    if (!evaluation || evaluation?.anamnesis?.id) return

    let mounted = true

    debouncer(async () => {
      if (queryDone.current) return
      setLoading(true)
      const data = await GETAnamnesisRecord(student.id, evaluation.id)

      if (mounted) {
        setAnamnesis(data)
        setLoading(false)
        queryDone.current = true
        data && updateEvaluation({ anamnesis: data })
      }
    }, 1000)

    return () => {
      mounted = false
    }
  }, [student, debouncer, updateEvaluation, evaluation])

  return (
    <studentAnamnesisContext.Provider
      value={{
        loading,
        anamnesis,
      }}
    >
      {children}
      {loading ? (
        <Modal>
          <div className='flex flex-col flex-1 gap-4'>
            <p className='text-center'>Carregando dados de Anamnese...</p>
            <div className='h-8 w-8 border-4 border-x-slate-200 border-y-slate-200 border-t-slate-400 rounded-full mx-auto animate-spin' />
          </div>
        </Modal>
      ) : null}
    </studentAnamnesisContext.Provider>
  )
}

export { studentAnamnesisContext, StudentAnamnesisProvider }
