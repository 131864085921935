import { Button } from '@components/Button'
import { QuestionFrame } from '@components/QuestionFrame'
import { SelectInput } from '@components/SelectInput'
import { FiChevronRight } from 'react-icons/fi'

export const CoronarianRiskFactorForm: React.FC = () => {
  return (
    <div>
      <form className='flex flex-col gap-6'>
        <QuestionFrame
          title='1) Atividade física realizada no dia a dia'
          isValid={false}
        >
          <SelectInput options={[]} />
        </QuestionFrame>
        <QuestionFrame title='2) Peso' isValid={false}>
          <SelectInput options={[]} />
        </QuestionFrame>
        <QuestionFrame
          title='3) Sexo e idade (Características sexuais)'
          isValid={false}
        >
          <SelectInput options={[]} />
        </QuestionFrame>
        <QuestionFrame title='4) Fumo' isValid={false}>
          <SelectInput options={[]} />
        </QuestionFrame>
        <QuestionFrame title='5) Pressão arterial sistólica' isValid={false}>
          <SelectInput options={[]} />
          <div className='flex gap-4'>
            <SelectInput options={[]} />
            <SelectInput options={[]} />
          </div>
        </QuestionFrame>
        <QuestionFrame title='6) Hereditariedade' isValid={false}>
          <SelectInput options={[]} />
        </QuestionFrame>
        <QuestionFrame
          title='7) Colesterol ou percentual de gordura na dieta'
          isValid={false}
        >
          <SelectInput options={[]} />
        </QuestionFrame>
        <div className='flex justify-end'>
          <Button>
            Avançar <FiChevronRight size={24} />
          </Button>
        </div>
      </form>
    </div>
  )
}
