import { useCallback, useState } from 'react'
import { FiX } from 'react-icons/fi'
import { Button } from '@components/Button'
import { Modal } from '@components/Modal'

import { IUpdateStudentDTO } from '@services/api/students/IUpdateStudentDTO'
import { PUTUpdateStudent } from '@services/api/students'
import studentsList from '@observables/studentsList'

import { UpdateStudentForm } from './updateStudentForm'

export const UpdateStudent: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>()

  const handleToggleUpdateStudentForm = useCallback(() => {
    setIsModalVisible((state) => !state)
  }, [])

  const handleSubmitForm = useCallback(async (formData: IUpdateStudentDTO) => {
    const [student, error] = await PUTUpdateStudent(formData)

    if (student) studentsList.updateStudentList(student)
    if (!error) setIsModalVisible(false)
  }, [])

  return (
    <>
      <Button
        className='flex-1'
        mode='outlined'
        onClick={handleToggleUpdateStudentForm}
      >
        Ver perfil
      </Button>
      {isModalVisible ? (
        <Modal>
          <div className='flex flex-col gap-3'>
            <div className='flex items-center justify-between'>
              <h2 className='text-xl font-medium'>Dados atuais do avaliado</h2>
              <button
                className='bg-white hover:bg-gpa-gray-50 p-1 rounded-full'
                onClick={handleToggleUpdateStudentForm}
              >
                <FiX size={24} />
              </button>
            </div>
            <UpdateStudentForm onSubmit={handleSubmitForm} />
            <Button mode='outlined' onClick={handleToggleUpdateStudentForm}>
              Cancelar
            </Button>
          </div>
        </Modal>
      ) : null}
    </>
  )
}
