import { useCallback, useMemo, useState } from 'react'
import {
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { Button } from '@components/Button'
import { FormStepIndicator } from '@components/FormStepIndicator'
import { StudentTag } from '@components/StudentTag'
import { useStudentEvaluation } from '@hooks/useStudentEvaluation'
import { StudentAnamnesisProvider } from '@contexts/studentAnamnesisContext'

import { AntropometryForm } from './components/antropometryForm'
import { CorporalEvaluationForm } from './components/corporaEvaluationForm'
import { HemodynamicMeasuresForm } from './components/hemodynamicMeasuresForm'
import { AerobicCapabilitiesForm } from './components/aerobicCapabitiliesForm'
import { AnamnesisForm } from './components/anamnesisForm'
import { ExercisesForm } from './components/exercisesForm'
import { Modal } from '@components/Modal'
import { AnimatePresence } from 'framer-motion'
import { DELETEEvaluation } from '@services/api/students/evaluations'

const forms: SectionForm[] = [
  { path: 'geral', label: 'Geral' },
  { path: 'antropometria', label: 'Antropometria' },
  { path: 'avaliacao_corporal', label: 'Avaliação Corporal' },
  { path: 'medidas_hemodinamicas', label: 'Medidas Hemodinâmicas' },
  { path: 'capacidade_aerobita', label: 'Capacidade Aeróbita' },
  { path: 'exercicios', label: 'Exercícios' },
]

export const StudentEvaluation: React.FC = () => {
  const { loading, evaluation } = useStudentEvaluation()
  const isFormValid = useMemo<boolean>(() => false, [])
  const {
    state: { student },
  } = useLocation()
  const navigate = useNavigate()
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [loadingDeleteEvaluation, setLoadingDeleteEvaluation] =
    useState<boolean>(false)

  const deleteEvaluation = useCallback(async () => {
    if (!evaluation) return

    setLoadingDeleteEvaluation(true)
    const [status] = await DELETEEvaluation({
      studentId: student.id,
      evaluationId: evaluation.id,
    })
    setLoadingDeleteEvaluation(false)

    if (status === 204) {
      setShowDeleteModal(false)
      navigate(-1)
    }
  }, [navigate, student, evaluation])

  const navigateBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const toggleDeleteEvaluationModal = useCallback(() => {
    setShowDeleteModal((state) => !state)
  }, [])

  return loading ? null : (
    <Routes>
      <Route
        path='/'
        element={
          <div className='flex flex-col overflow-hidden max-w-[851px] gap-6'>
            <header className='flex flex-col gap-6 p-2.5 bg-white rounded-2xl'>
              <div className='flex justify-between items-center'>
                <StudentTag
                  name={student.name}
                  email={student.email}
                  phoneNumber={student.phoneNumber[0]}
                  picture=''
                />
                <div className='flex gap-2'>
                  <Button
                    onClick={toggleDeleteEvaluationModal}
                    className='bg-white gap-1 hover:bg-slate-50 enabled:active:bg-red-50 !text-gpa-red'
                  >
                    Apagar registro
                  </Button>
                  <Button
                    onClick={navigateBack}
                    className='bg-white hover:bg-slate-50 enabled:active:bg-slate-100 !text-gpa-blue-500'
                  >
                    Voltar
                  </Button>
                </div>
                <AnimatePresence>
                  {showDeleteModal ? (
                    <Modal>
                      <div className='flex flex-col gap-8'>
                        <h1 className='text-lg'>
                          Deseja realmente apagar este registro?
                        </h1>
                        <p>Todos os dados desta avaliação serão perdidos</p>
                        <div className='flex justify-end gap-3'>
                          <Button
                            loading={loadingDeleteEvaluation}
                            onClick={deleteEvaluation}
                            className='bg-gpa-red enabled:active:bg-red-500'
                          >
                            Apagar
                          </Button>
                          <Button
                            mode='outlined'
                            onClick={toggleDeleteEvaluationModal}
                            className='border-gpa-red text-gpa-red enabled:active:bg-red-50'
                          >
                            Cancelar
                          </Button>
                        </div>
                      </div>
                    </Modal>
                  ) : null}
                </AnimatePresence>
              </div>
              <nav className='flex gap-4 snap-x snap-mandatory scroll-smooth overscroll-contain scrollbar-hidden overflow-hidden overflow-x-auto pb-3'>
                {forms.map((item, index) => (
                  <Link
                    to={item.path}
                    key={`form-${index}`}
                    className='snap-start'
                    replace
                    state={{ student }}
                  >
                    <FormStepIndicator
                      step={index + 1}
                      title={item.label}
                      seen={isFormValid || item.path === 'geral'}
                    />
                  </Link>
                ))}
              </nav>
            </header>

            <Outlet />
          </div>
        }
      >
        <Route
          path='geral'
          element={
            <StudentAnamnesisProvider>
              <AnamnesisForm />
            </StudentAnamnesisProvider>
          }
        />
        <Route path='antropometria' element={<AntropometryForm />} />
        <Route path='avaliacao_corporal' element={<CorporalEvaluationForm />} />
        <Route
          path='medidas_hemodinamicas'
          element={<HemodynamicMeasuresForm />}
        />
        <Route
          path='capacidade_aerobita'
          element={<AerobicCapabilitiesForm />}
        />
        <Route path='exercicios' element={<ExercisesForm />} />
        <Route
          path='/'
          element={<Navigate to={'geral'} state={{ student }} replace />}
        />
      </Route>
    </Routes>
  )
}
