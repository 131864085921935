import { useCallback, useState } from 'react'
import { FiPlus, FiTrash } from 'react-icons/fi'
import { Controller, useFieldArray, useForm } from 'react-hook-form'

import { TextInput } from '@components/TextInput'
import { zodResolver } from '@hookform/resolvers/zod'
import { SelectInput } from '@components/SelectInput'
import { genderOptions, maritalStatusOptions } from '@layout/TopBar/variables'
import { Button } from '@components/Button'
import {
  dateMask,
  documentNumberMask,
  phoneMask,
  socialDocumentNumberMask,
} from '@components/TextInput/utils/mask'

import { schema } from './schema'
import { UpdateStudentFormProps } from './types'
import { useAuth } from '@hooks/useAuth'
import { IUpdatePersonalTrainerDTO } from '@services/api/personalTrainers/IUpdatePersonalTrainerDTO'
import { useNavigate } from 'react-router-dom'

export const UpdatePersonalTrainerForm: React.FC<UpdateStudentFormProps> = ({
  onSubmit,
}) => {
  const { authData } = useAuth()
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IUpdatePersonalTrainerDTO>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: authData?.user.name,
      maritalStatus: authData?.user.maritalStatus,
      birthday: dateMask(authData?.user.birthday || ''),
      phoneNumber: authData?.user.phoneNumber.map((phone) => phoneMask(phone)),
      documentNumber: documentNumberMask(authData?.user.documentNumber || ''),
      email: authData?.user.email,
      sex: authData?.user.sex,
      socialDocumentNumber: socialDocumentNumberMask(
        authData?.user.socialDocumentNumber || '',
      ),
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'phoneNumber' as never,
  })

  const handleSubmitForm = useCallback(
    (data: IUpdatePersonalTrainerDTO) => {
      setLoading(true)
      onSubmit && onSubmit(data)
      setLoading(false)
    },
    [onSubmit],
  )

  return (
    <form className='flex flex-col' onSubmit={handleSubmit(handleSubmitForm)}>
      <TextInput
        label='Nome'
        {...register('name')}
        error={errors.name?.message}
      />

      <div className='flex flex-1 flex-wrap gap-3'>
        <Controller
          control={control}
          name='birthday'
          render={({ field }) => (
            <TextInput
              value={field.value}
              onChange={(e) => field.onChange(dateMask(e.target.value))}
              label='Data de nascimento'
              error={errors.birthday?.message}
            />
          )}
        />
        <TextInput
          label='E-mail'
          {...register('email')}
          error={errors.email?.message}
        />
      </div>

      <div className='flex flex-1 flex-wrap gap-3'>
        <Controller
          control={control}
          name='sex'
          render={({ field }) => (
            <SelectInput
              label='Sexo'
              onChange={field.onChange}
              value={field.value}
              options={genderOptions.map((item) => ({
                label: item,
                value: item,
              }))}
              error={errors.sex?.message}
            />
          )}
        />
        <Controller
          control={control}
          name='maritalStatus'
          render={({ field }) => (
            <SelectInput
              label='Estado civil'
              value={field.value}
              onChange={field.onChange}
              options={maritalStatusOptions.map((item) => ({
                label: item,
                value: item,
              }))}
              error={errors.maritalStatus?.message}
            />
          )}
        />
      </div>

      <div className='flex flex-1 flex-wrap gap-3'>
        <Controller
          control={control}
          name='documentNumber'
          render={({ field }) => (
            <TextInput
              value={field.value}
              onChange={(e) =>
                field.onChange(documentNumberMask(e.target.value))
              }
              label='CPF'
              error={errors.documentNumber?.message}
            />
          )}
        />
        <Controller
          control={control}
          name='socialDocumentNumber'
          render={({ field }) => (
            <TextInput
              value={field.value}
              onChange={(e) =>
                field.onChange(socialDocumentNumberMask(e.target.value))
              }
              label='RG'
              error={errors.socialDocumentNumber?.message}
            />
          )}
        />
      </div>
      <div className='flex flex-1 flex-wrap gap-3'>
        <TextInput
          label='Nova senha (não obrigatório)'
          type='password'
          {...register('password')}
          error={errors.password?.message}
        />
        <TextInput
          label='Confirmar senha (não obrigatório)'
          type='password'
          {...register('confirm_password')}
          error={errors.confirm_password?.message}
        />
      </div>

      <div className='flex flex-col gap-3 mb-3'>
        {fields.map((item, index) => (
          <div key={item.id} className='flex w-full gap-4'>
            <Controller
              control={control}
              name={`phoneNumber.${index}`}
              render={({ field }) => (
                <TextInput
                  value={field.value}
                  onChange={(e) => field.onChange(phoneMask(e.target.value))}
                  label='Telefone'
                  error={
                    errors.phoneNumber?.length
                      ? errors.phoneNumber[index]?.message
                      : undefined
                  }
                />
              )}
            />
            {index ? (
              <button
                type='button'
                className='flex self-start rounded-full p-2 bg-gpa-blue text-gpa-gray-800 mt-10 font-medium hover:bg-gpa-gray-100'
                onClick={() => remove(index)}
              >
                <FiTrash size={24} />
              </button>
            ) : null}
          </div>
        ))}
        <button
          type='button'
          className='flex self-end rounded-lg py-2 px-4 bg-gpa-blue-500 text-white font-medium mb-4'
          onClick={() => append('')}
        >
          <FiPlus size={24} />
          <span>Novo número</span>
        </button>
      </div>

      <Button disabled={loading} loading={loading} className='w-full'>
        Atualizar
      </Button>

      <Button
        type='button'
        mode='outlined'
        disabled={loading}
        loading={loading}
        className='w-full mt-4'
        onClick={() => navigate(-1)}
      >
        Voltar
      </Button>
    </form>
  )
}
