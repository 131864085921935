import { PropsWithChildren } from 'react'
import { BsCheck } from 'react-icons/bs'

export const QuestionFrame: React.FC<PropsWithChildren<QuestionFrameProps>> = ({
  title,
  isValid,
  children,
}) => {
  return (
    <div className='flex flex-col border-2 border-gpa-gray-500 p-2 gap-5 rounded-md'>
      <div className='flex justify-between items-start gap-2'>
        <h3 className='font-bold'>{title}</h3>
        <div
          data-valid={isValid}
          className='shrink-0 h-[25px] w-[25px] border-2 data-[valid=true]:bg-gpa-blue-500 group border-gpa-blue-500 rounded-full'
        >
          <BsCheck className='text-white h-full w-full hidden group-data-[valid=true]:block' />
        </div>
      </div>
      {children}
    </div>
  )
}
