import { Button } from '@components/Button'
import { Checkbox } from '@components/Checkbox'
import { TextInput } from '@components/TextInput'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { schema } from './schema'

export const LoginForm: React.FC<LoginFormProps> = ({ onSubmit }) => {
  const [loading, setLoading] = useState<boolean>(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormData>({
    resolver: zodResolver(schema),
  })

  const handleSubmitForm = useCallback(
    (formData: LoginFormData) => {
      setLoading(true)
      onSubmit && onSubmit(formData)
      setLoading(false)
    },
    [onSubmit],
  )

  return (
    <form
      className='flex flex-col w-full gap-3'
      onSubmit={handleSubmit(handleSubmitForm)}
      autoComplete='off'
    >
      <TextInput
        {...register('documentNumber')}
        error={errors.documentNumber?.message}
        label='CPF'
      />
      <TextInput
        {...register('password')}
        error={errors.password?.message}
        label='Senha'
        type='password'
      />
      <Checkbox {...register('shouldRemember')} label='Manter meu acesso' />
      <Button disabled={loading} loading={loading} className='!h-14'>
        Entrar
      </Button>
      <span className='ml-auto text-gpa-blue-500'>Esqueci minha senha</span>
    </form>
  )
}
