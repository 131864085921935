import { promiseResolver } from '@utils/promiseResolver'
import { api } from '@utils/api'
import { AxiosError, AxiosResponse } from 'axios'
import { IDeleteEvaluationId } from './IDeleteEvaluationId'

export async function GETEvaluationRecord(
  studentId: string,
  evaluationId: string | null,
) {
  const [response] = await promiseResolver<
    AxiosResponse<Evaluation>,
    AxiosError
  >(api.get(`/students/${studentId}/evaluations/${evaluationId}`))

  return response?.data
}

export async function POSTCreateEvaluation(
  studentId: string,
): Promise<[Evaluation | undefined, AxiosError | null]> {
  const [response, error] = await promiseResolver<
    AxiosResponse<Evaluation>,
    AxiosError
  >(api.post(`/students/${studentId}/evaluations/`))

  return [response?.data, error]
}

export async function DELETEEvaluation(
  data: IDeleteEvaluationId,
): Promise<[number | undefined, AxiosError<APIError> | null]> {
  const [response, error] = await promiseResolver<
    AxiosResponse,
    AxiosError<APIError>
  >(api.delete(`/students/${data.studentId}/evaluations/${data.evaluationId}`))

  return [response?.status, error]
}
