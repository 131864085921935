import { Button } from '@components/Button'
import { useStudentCard } from '../useStudentCard'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { studentEvaluationsRoute } from '@routes/routes'
import { POSTCreateEvaluation } from '@services/api/students/evaluations'

export const NewEvaluationButton: React.FC = () => {
  const navigate = useNavigate()
  const { student } = useStudentCard()
  const [loading, setLoading] = useState<boolean>(false)

  const handleCreateEvaluation = useCallback(async () => {
    setLoading(true)
    const [evaluation] = await POSTCreateEvaluation(student.id)
    setLoading(false)

    if (evaluation) {
      navigate(
        studentEvaluationsRoute
          .replace(':student_id', student.id)
          .replace(':evaluation_id', evaluation.id),
        {
          state: { student },
        },
      )
    }
  }, [student, navigate])

  return (
    <Button
      className='flex-1'
      loading={loading}
      onClick={handleCreateEvaluation}
    >
      Nova avaliação
    </Button>
  )
}
