export async function promiseResolver<T, T2>(
  promise: Promise<T>,
): Promise<[T | null, null | T2]> {
  try {
    const data = await promise
    return [data, null]
  } catch (error) {
    return [null, error as T2]
  }
}
