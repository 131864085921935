import { LayoutContext } from '@layout/PersonalTrainerAreaLayout'
import { useContext } from 'react'

export function useLayout() {
  const context = useContext(LayoutContext)

  if (Object.keys(context).length === 0) {
    throw new Error('useLayout must be used within a Layout Provider')
  }

  return context
}
