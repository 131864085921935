import {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { PageContainer } from './PageContainer'
import { TopBar } from './TopBar'

export const LayoutContext = createContext<LayoutContextData>(
  {} as LayoutContextData,
)

export const PersonalTrainerAreaLayout: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const mainRef = useRef<HTMLDivElement>(null)
  const [hasScrolled, setHasScrolled] = useState<boolean>(false)

  const scrollToTop = useCallback(() => {
    mainRef.current?.scrollTo({
      top: 0,
    })
  }, [])

  useEffect(() => {
    const pageContainer = mainRef.current

    const handdleScroll = () => {
      if (pageContainer) {
        setHasScrolled(pageContainer.scrollTop > 0)
      }
    }
    if (pageContainer) {
      mainRef.current.addEventListener('scroll', handdleScroll)
    }

    return () => {
      if (pageContainer) {
        pageContainer.removeEventListener('scroll', handdleScroll)
      }
    }
  }, [])

  return (
    <PageContainer ref={mainRef}>
      <LayoutContext.Provider value={{ scrollToTop, hasScrolled }}>
        <TopBar />
        <main className='flex flex-1 px-6 py-7 bg-gpa-gray-50'>{children}</main>
      </LayoutContext.Provider>
    </PageContainer>
  )
}
