import { BrowserRouter } from 'react-router-dom'
import { AuthenticatedRoutes } from './Authenticated'
import { OpenRoutes } from './Open'
import { useAuth } from '@hooks/useAuth'

export const AppRouter: React.FC = () => {
  const { authData } = useAuth()

  return (
    <BrowserRouter>
      {authData ? <AuthenticatedRoutes /> : <OpenRoutes />}
    </BrowserRouter>
  )
}
