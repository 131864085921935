import zod from 'zod'
import {
  exerciseIntensityLevelEnum,
  availableTimeEnum,
  availableWeekDaysEnum,
  exercisesEnum,
  goalsEnum,
  periodEnum,
  presentPhysicalConditioningEnum,
  exercisesLocaleEnum,
  regularMealsEnum,
  dietEnum,
  efforTestEnum,
  alcoholicDrinksEnum,
  litersPerDayEnum,
  diabetesEnum,
  glicemyLevelEnum,
  cholesterolLevelsEnum,
  triglyceridesLevelsEnum,
  tabagismEnum,
  diseasesEnum,
  medicationEnum,
  jointMuscularDamageEnum,
  pedigreeEnum,
} from './variables'

export const generalFormSchema = zod.object({
  goals: zod
    .enum(goalsEnum)
    .array()
    .nonempty('É preciso selecionar ao menos 1 item'),
  presentPhysicalConditioning: zod.enum(presentPhysicalConditioningEnum, {
    required_error: 'Campo obrigatório',
  }),
  physicalConditioningTimePeriod: zod.object({
    period: zod.enum(periodEnum, { required_error: 'Campo obrigatório' }),
    quantity: zod
      .number({
        required_error: 'Campo obrigatório',
        invalid_type_error: 'Deve ser um número',
      })
      .nonnegative('Não deve ser negativo')
      .min(0),
  }),
  availableDaysPerWeek: zod
    .number({
      required_error: 'Campo obrigatório',
    })
    .min(1)
    .max(7),
  availableTime: zod.enum(availableTimeEnum, {
    required_error: 'Campo obrigatório',
  }),
  availableWeekDaysForPhysical: zod
    .array(zod.enum(availableWeekDaysEnum), {
      invalid_type_error: 'É preciso selecionar ao menos 1 item',
    })
    .nonempty('É preciso selecionar ao menos 1 item'),
  currentExercises: zod
    .object({
      exercises: zod.array(zod.enum(exercisesEnum)),
      other: zod.string(),
    })
    .refine(
      ({ exercises, other }) => !(exercises.length === 0 && other.length === 0),
      {
        message: 'Ao menos um valor deve ser preenchido',
      },
    ),
  exercisesIntensity: zod.enum(exerciseIntensityLevelEnum, {
    required_error: 'Obrigatório',
  }),
  exercisesLocale: zod.enum(exercisesLocaleEnum, {
    required_error: 'Obrigatório',
  }),
  regularMeals: zod
    .array(zod.enum(regularMealsEnum), {
      invalid_type_error: 'É preciso selecionar ao menos 1 item',
    })
    .nonempty('É preciso selecionar ao menos 1 item'),
  diet: zod.enum(dietEnum, { required_error: 'Campo obrigatório' }),
  suplements: zod.string().optional(),
  effortTest: zod.enum(efforTestEnum, { required_error: 'Campo obrigatório' }),
  alcoholicDrinks: zod.enum(alcoholicDrinksEnum, {
    required_error: 'Campo obrigatório',
  }),
  litersPerDay: zod.enum(litersPerDayEnum, {
    required_error: 'Campo obrigatório',
  }),
  diabetes: zod.enum(diabetesEnum, { required_error: 'Campo obrigatório' }),
  glicemyLevel: zod.enum(glicemyLevelEnum, {
    required_error: 'Campo obrigatório',
  }),
  cholesterolLevels: zod.enum(cholesterolLevelsEnum, {
    required_error: 'Campo obrigatório',
  }),
  triglyceridesLevels: zod.enum(triglyceridesLevelsEnum, {
    required_error: 'Campo obrigatório',
  }),
  tabagism: zod.enum(tabagismEnum, { required_error: 'Campo obrigatório' }),
  diseases: zod
    .object({
      diseases: zod.array(zod.enum(diseasesEnum)),
      other: zod.string(),
    })
    .refine(
      ({ diseases, other }) => !(diseases.length === 0 && other.length === 0),
      {
        message: 'Ao menos um valor deve ser preenchido',
      },
    ),
  regularMedications: zod
    .object({
      medications: zod.array(zod.enum(medicationEnum)),
      other: zod.string(),
    })
    .refine(
      ({ medications, other }) =>
        !(medications.length === 0 && other.length === 0),
      {
        message: 'Ao menos um valor deve ser preenchido',
      },
    ),
  jointMuscularDamage: zod
    .array(zod.enum(jointMuscularDamageEnum), {
      invalid_type_error: 'É preciso selecionar ao menos 1 item',
    })
    .nonempty('É preciso selecionar ao menos 1 item'),
  pedigree: zod
    .array(zod.enum(pedigreeEnum), {
      invalid_type_error: 'É preciso selecionar ao menos 1 item',
    })
    .nonempty('É preciso selecionar ao menos 1 item'),
  commentary: zod.string().optional(),
})
