import { Navigate, useLocation, useParams } from 'react-router-dom'

import { Modal } from '@components/Modal'
import { GETEvaluationRecord } from '@services/api/students/evaluations'
import { debounce } from '@utils/debounce'
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

const studentEvaluationContext = createContext<StudentEvaluationContextData>(
  {} as StudentEvaluationContextData,
)

const StudentEvaluationProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { state } = useLocation()
  const { evaluation_id } = useParams<EvaluationRouteParams>()
  const [loading, setLoading] = useState<boolean>(true)
  const [evaluation, setEvaluation] = useState<Evaluation>()

  // Upgrade to query hook (react-query?)
  const debouncer = useMemo(() => debounce(), [])
  const queryDone = useRef<boolean>(false)

  const updateEvaluation = useCallback((payload: AnamnesisSubformPayload) => {
    setEvaluation((state) => (state ? { ...state, ...payload } : state))
  }, [])

  useEffect(() => {
    let mounted = true

    debouncer(async () => {
      if (queryDone.current || !state?.student || !evaluation_id) return
      setLoading(true)
      const evaluation = await GETEvaluationRecord(
        state.student.id,
        evaluation_id,
      )

      if (mounted) {
        setEvaluation(evaluation)
        setLoading(false)
        queryDone.current = true
      }
    }, 1000)

    return () => {
      mounted = false
    }
  }, [state, debouncer, evaluation_id])

  if (!state?.student || !evaluation_id) return <Navigate to='/' replace />

  return (
    <studentEvaluationContext.Provider
      value={{
        loading,
        evaluation,
        updateEvaluation,
      }}
    >
      {children}
      {loading ? (
        <Modal>
          <div className='flex flex-col flex-1 gap-4'>
            <p className='text-center'>Carregando dados da avaliação...</p>
            <div className='h-8 w-8 border-4 border-x-slate-200 border-y-slate-200 border-t-slate-400 rounded-full mx-auto animate-spin' />
          </div>
        </Modal>
      ) : null}
    </studentEvaluationContext.Provider>
  )
}

export { studentEvaluationContext, StudentEvaluationProvider }
