import { Navigate, Route, Routes } from 'react-router-dom'
import { loginRoute } from './routes'
import { Login } from '@pages/open/login'

export const OpenRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={loginRoute} element={<Login />} />
      <Route path='*' element={<Navigate to={loginRoute} replace />} />
    </Routes>
  )
}
