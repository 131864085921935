type StudentsListActionPayload = {
  id: string
  callback: CallableFunction
}

function studentsList() {
  const actions: StudentsListActionPayload[] = []

  const subscribe = (payload: StudentsListActionPayload) => {
    actions.push(payload)
  }

  const unsubscribe = (id: string) => {
    const subscriptionId = actions.findIndex((action) => action.id !== id)
    actions.splice(subscriptionId, 1)
  }

  const addStudentToList = (student: Student) => {
    actions.forEach((action) => {
      action.callback((state: Student[]) => [student, ...state])
    })
  }

  const updateStudentList = (student: Student) => {
    actions.forEach((action) => {
      action.callback((students: Student[]) =>
        students.map((s) => (s.id !== student.id ? s : student)),
      )
    })
  }

  return {
    subscribe,
    unsubscribe,
    addStudentToList,
    updateStudentList,
  }
}

export default studentsList()
