import { promiseResolver } from '@utils/promiseResolver'
import { ICreateStudentDTO } from './ICreateStudentDTO'
import { AxiosError, AxiosResponse } from 'axios'
import { api } from '@utils/api'
import { IPaginatedStudentIndexDTO } from './IPaginatedStudentIndexDTO'
import { IUpdateStudentDTO } from './IUpdateStudentDTO'

export async function POSTCreateStudent(
  payload: ICreateStudentDTO,
): Promise<[Student | undefined, AxiosError | null]> {
  const [response, error] = await promiseResolver<
    AxiosResponse<Student>,
    AxiosError
  >(api.post('/students', payload))

  return [response?.data, error]
}

export async function GETStudentList(params?: IndexStudentQueryParams) {
  const [response] = await promiseResolver<
    AxiosResponse<IPaginatedStudentIndexDTO>,
    AxiosError
  >(api.get('/students', { params }))

  return response?.data
}

export async function PUTUpdateStudent({
  id,
  ...rest
}: IUpdateStudentDTO): Promise<[Student | undefined, AxiosError | null]> {
  const [response, error] = await promiseResolver<
    AxiosResponse<Student>,
    AxiosError
  >(api.put(`/students/${id}`, rest))

  return [response?.data, error]
}
