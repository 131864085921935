import {
  FocusEvent,
  KeyboardEvent,
  MouseEvent,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { ISelectInputProps } from './ISelectInputProps'
import { HandleStopPropagation } from './helpers'

const Component: React.ForwardRefRenderFunction<
  HTMLInputElement,
  ISelectInputProps
> = ({ options, label, error, onFocus, onChange, value, ...rest }, ref) => {
  const optionsContainerRef = useRef<HTMLDivElement>(null)
  const labelRef = useRef<HTMLLabelElement>(null)
  const [selectedValue, setSelectedValue] = useState<Option>()
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [isShowingOptions, setIsShowingOptions] = useState<boolean>(false)

  const FLIPfunction = useCallback(() => {
    const element = optionsContainerRef.current
    if (element) {
      const first = element.getBoundingClientRect()
      const windowHeight = window.innerHeight
      const offset = 100

      // mobile
      if (windowHeight < first.height * 2 + offset) {
        element.style.bottom = `${offset}px`
        element.style.position = 'fixed'
        element.style.top = '50%'
        element.style.left = '50%'
        element.style.bottom = ''
        element.style.transform = 'translate3D(-50%, -50%, 0)'
        return
      }

      // desktop screens
      if (first.bottom > windowHeight) {
        element.style.position = 'absolute'
        element.style.top = ''
        element.style.bottom = `${offset}px`
      } else if (first.top < 5) {
        element.style.position = 'absolute'
        element.style.bottom = ''
        element.style.top = `${offset}px`
      }
    }
  }, [])

  const handleSelectOption = useCallback(
    (option: Option) => {
      setSelectedValue(option)
      onChange && onChange(option.value || 'true')
      setCurrentIndex(0)
      setIsShowingOptions(false)
    },
    [onChange],
  )

  const handleOpenOptions = useCallback(
    (event?: MouseEvent<HTMLElement>) => {
      setIsShowingOptions((state) => {
        if (state && event) HandleStopPropagation(event)
        return true
      })
      requestAnimationFrame(() => {
        FLIPfunction()
      })
    },
    [FLIPfunction],
  )

  const handleInputFocus = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      handleOpenOptions()
      onFocus && onFocus(event)
    },
    [onFocus, handleOpenOptions],
  )

  const handleKeyPress = useCallback(
    function (this: Window, event: KeyboardEvent<HTMLInputElement>) {
      if (!isShowingOptions && event.key === 'Enter') {
        setIsShowingOptions(true)
      } else if (!isShowingOptions) return

      let index = currentIndex

      if (event.key === 'ArrowUp') {
        event.preventDefault()
        if (index === 0) index = options.length - 1
        else index = Math.abs((index - 1) % options.length)
        setCurrentIndex(index)
        ;(
          optionsContainerRef.current?.firstChild?.childNodes[
            index
          ] as HTMLElement
        ).scrollIntoView({
          block: 'nearest',
          inline: 'center',
        })
      } else if (event.key === 'ArrowDown') {
        event.preventDefault()
        index = (index + 1) % options.length
        setCurrentIndex(index)
        ;(
          optionsContainerRef.current?.firstChild?.childNodes[
            index
          ] as HTMLElement
        ).scrollIntoView({
          block: 'nearest',
          inline: 'start',
        })
      } else if (event.key === 'Enter' || event.key === 'Tab') {
        event.preventDefault()
        handleSelectOption(options[index])
        setIsShowingOptions(false)
      } else if (event.key === 'Escape') {
        event.preventDefault()
        setIsShowingOptions(false)
      }
    },
    [handleSelectOption, isShowingOptions, options, currentIndex],
  )

  useEffect(() => {
    FLIPfunction()
    window.addEventListener('scroll', FLIPfunction)
    return () => {
      window.removeEventListener('scroll', FLIPfunction)
    }
  }, [FLIPfunction, handleKeyPress])

  const handleDocumentTouch = useCallback(() => {
    setIsShowingOptions(false)
  }, [])

  useEffect(() => {
    document.addEventListener('click', handleDocumentTouch)

    return () => {
      document.removeEventListener('click', handleDocumentTouch)
    }
  }, [handleDocumentTouch])

  useEffect(() => {
    if (options) {
      const defaultOption = options.find((item) => item.value === value)
      if (defaultOption) setSelectedValue(defaultOption)
    }
  }, [options, value])

  return (
    <div className='relative flex flex-1 flex-col gap-3 min-w-[244px]'>
      <p className={error ? 'text-gpa-red' : 'text-black'}>{label}</p>
      <label
        ref={labelRef}
        className={
          'flex flex-row items-center px-4 focus-within:border-gpa-blue-500 py-2 h-[48px] border-2 rounded-md gap-2 ' +
          (error ? 'border-gpa-red ' : 'border-gpa-gray-500')
        }
        onClick={handleOpenOptions}
      >
        <input
          ref={ref}
          {...rest}
          type='text'
          readOnly
          value={selectedValue?.label || ''}
          onFocus={handleInputFocus}
          className='flex-1 min-w-0 outline-none pointer-events-none bg-transparent'
          onKeyDown={handleKeyPress}
        />
        <div>
          {isShowingOptions ? (
            <FiChevronUp size={23} className='text-bold text-gpa-gray-800' />
          ) : (
            <FiChevronDown size={23} className='text-bold text-gpa-gray-800' />
          )}
        </div>
      </label>
      <span
        className={`flex text-xs text-gpa-red ${
          error ? 'visible' : 'invisible'
        }`}
      >
        {error}&nbsp;
      </span>
      <div
        ref={optionsContainerRef}
        className='z-30 max-h-[300px] w-full'
        style={{
          display: isShowingOptions ? 'flex' : 'none',
          position: 'absolute',
          top: 130,
        }}
      >
        <ul
          className='flex flex-1 flex-col bg-white border-2 rounded-md  overflow-y-auto py-3 mx-3 shadow-xl'
          onClick={HandleStopPropagation}
        >
          {options.map((item, index) => (
            <li
              key={`${rest.name}-${index}`}
              className={
                'flex px-4 py-2 cursor-pointer hover:bg-slate-100 ' +
                (index === currentIndex ? '!bg-slate-200' : 'bg-white')
              }
              onClick={() => handleSelectOption(item)}
            >
              {item.label}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export const SelectInput = forwardRef(Component)
