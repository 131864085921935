import { PropsWithChildren, useEffect, useRef } from 'react'
import { useAuth } from '@hooks/useAuth'
import { api } from '@utils/api'
import { AxiosError } from 'axios'
import toast from '@observables/toast'

export const AuthGate: React.FC<PropsWithChildren> = ({ children }) => {
  const { signOut } = useAuth()
  const initRef = useRef<boolean>(false)
  useEffect(() => {
    if (initRef.current) return
    api.interceptors.response.use(
      (response) => response,
      (error: AxiosError<{ message: string }>) => {
        toast.insert({
          message: error.response?.data.message,
          title: 'Alerta!',
          type: 'error',
          duration: 4,
        })
        if (error.response?.status === 401 || error.response?.status === 403) {
          signOut()
        }

        return Promise.reject(error)
      },
    )
    initRef.current = true
  }, [signOut])

  return <>{children}</>
}
