type ToastActionPayload = {
  id: string
  callback: (toast: ToastDTO) => void
}

function toastObserver() {
  const actions: ToastActionPayload[] = []

  const subscribe = (data: ToastActionPayload) => {
    const alreadyRegistered = actions.some((action) => action.id === data.id)
    if (!alreadyRegistered) actions.push(data)
  }

  const unsubscribe = (id: string) => {
    actions.splice(
      actions.findIndex((item) => item.id === id),
      1,
    )
  }

  const insert = (toast: Omit<ToastDTO, 'id'>) => {
    actions.forEach((action) => {
      action.callback({ id: Math.random(), ...toast })
    })
  }

  return {
    subscribe,
    unsubscribe,
    insert,
  }
}

export default toastObserver()
