import { PropsWithChildren } from 'react'
import { IButtonProps } from './IButtonProps'
import { Loading } from './components/Loading'

export const Button: React.FC<PropsWithChildren<IButtonProps>> = ({
  mode = 'filled',
  loading,
  loadingMessage,
  className,
  children,
  ...rest
}) => {
  return (
    <button
      className={
        'flex shrink-0 whitespace-nowrap justify-center items-center px-4 h-[38px] rounded-lg cursor-pointer disabled:cursor-not-allowed select-none font-semibold text-base disabled:opacity-60' +
        (mode === 'filled'
          ? ' bg-gpa-blue-500 text-white enabled:active:bg-[#1b5b9a]'
          : ' border-gpa-blue-500 border-2 bg-white text-gpa-blue-500 enabled:active:bg-slate-50') +
        ` ${className}`
      }
      {...rest}
    >
      {loading ? <Loading>{loadingMessage}</Loading> : children}
    </button>
  )
}
