import { PropsWithChildren } from 'react'
import { motion } from 'framer-motion'

export const Modal: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className='fixed z-30'>
      <motion.div
        className='fixed flex top-0 left-0  h-full w-full z-30 bg-[#cecece] bg-opacity-20  filter backdrop-blur-sm'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          duration: 0.2,
        }}
      />
      <motion.div
        className='fixed z-40 top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 p-6 rounded-md bg-white max-w-[700px] w-full overflow-hidden overflow-y-auto'
        initial={{ transform: 'translate3d(-50%, 1500%, 0)' }}
        animate={{ transform: 'translate3d(-50%, -50%, 0)' }}
        exit={{ transform: 'translate3d(-50%, 200%, 0)' }}
        transition={{
          duration: 0.4,
          ease: [0, 0.71, 0.2, 1],
        }}
        style={{
          maxHeight: window.innerHeight - window.innerHeight * 0.05,
        }}
      >
        {children}
      </motion.div>
    </div>
  )
}
