import { FiSearch } from 'react-icons/fi'
import { PageNavigation } from '@components/PageNavigation/index'
import { StudentCard } from '@components/StudentCard'
import { TextInput } from '@components/TextInput'
import { GETStudentList } from '@services/api/students'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { debounce } from '@utils/debounce'
import studentsList from '@observables/studentsList'

export const PersonalTrainerHome: React.FC = () => {
  const [loading, setloading] = useState<boolean>(false)
  const [studentList, setStudentList] = useState<Student[]>([])
  const [metadata, setMetadata] = useState<RequestMetaData>()
  const [page, setPage] = useState<number>(0)
  const [query, setQuery] = useState<string>('')

  const debouncer = useMemo(() => debounce(), [])

  const updateQuery = useCallback(
    (newQuery: string) => {
      debouncer(() => {
        setQuery(newQuery)
      })
    },
    [debouncer],
  )

  const updatePage = useCallback((pageNumber: number) => {
    setPage(pageNumber)
  }, [])

  useEffect(() => {
    let mounted = true

    studentsList.subscribe({
      id: 'personalTrainerHome',
      callback: setStudentList,
    })
    ;(async () => {
      setloading(true)
      const data = await GETStudentList({
        page,
        ...(query ? { name: query } : null),
      })

      if (mounted && data?.students) {
        setStudentList(data?.students)
        setMetadata(data?.meta)
        setloading(false)
      }
    })()

    return () => {
      mounted = false
      studentsList.unsubscribe('personalTrainerHome')
    }
  }, [page, query])

  return (
    <div className='flex flex-1 flex-col items-end px-6 py-7 gap-4'>
      <div className='flex flex-wrap justify-between items-center p-3 gap-2 bg-white  w-full rounded-2xl'>
        <h1 className='font-semibold text-2xl'>Alunos</h1>
        <div className='w-full md:w-auto'>
          <TextInput
            noError
            className=''
            onChange={(e) => updateQuery(e.target.value)}
            rightIconArea={[
              <FiSearch
                key='search-input'
                size={24}
                className='text-gpa-gray-500'
              />,
            ]}
          />
        </div>
      </div>

      {loading ? (
        <p className='text-center self-center'>Carregando...</p>
      ) : (
        <>
          {studentList?.length ? (
            <div className='grid grid-cols-[repeat(auto-fit,_minmax(_245px,324px))] justify-center grid-flow-row w-full gap-4'>
              {studentList?.map((student, index) => (
                <StudentCard key={`student-${index}`} student={student} />
              ))}
            </div>
          ) : (
            <p className='text-center self-center mt-12'>
              Nenhum registro encontrado.
            </p>
          )}
          <PageNavigation
            currentPage={metadata?.page || 0}
            numPages={metadata?.totalPages || 0}
            onPageChange={(page) => updatePage(page)}
          />
        </>
      )}
    </div>
  )
}
