import { promiseResolver } from '@utils/promiseResolver'
import { AxiosError, AxiosResponse } from 'axios'
import { api } from '@utils/api'

export async function POSTGeneralForm(
  studentId: string,
  evaluationId: string,
  payload: General,
): Promise<[General | undefined, AxiosError | null]> {
  const [response, error] = await promiseResolver<
    AxiosResponse<General>,
    AxiosError
  >(
    api.post(
      `/students/${studentId}/evaluations/${evaluationId}/anamneses/general`,
      payload,
    ),
  )

  return [response?.data, error]
}
