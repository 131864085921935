import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import { useAuth } from '@hooks/useAuth'
import { Link } from 'react-router-dom'
import { personalTrainerProfileRoute } from '@routes/routes'

export const DropDownMenu: React.FC = () => {
  const { signOut } = useAuth()
  const menuContainerRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState<boolean>(false)

  const handleMenuClick = useCallback(() => {
    setOpen((state) => !state)
  }, [])

  const handleWindowClick = useCallback<any>(function (
    this: Document,
    e: MouseEvent,
  ) {
    if (e.target !== menuContainerRef.current) setOpen(false)
  },
  [])

  useEffect(() => {
    document.addEventListener('click', handleWindowClick)
    return () => {
      document.removeEventListener('click', handleWindowClick)
    }
  }, [handleWindowClick])

  return (
    <div
      ref={menuContainerRef}
      className='relative flex select-none justify-center items-center px-3 bg-white hover:bg-slate-100 rounded-lg cursor-pointer'
      onClick={handleMenuClick}
    >
      <p className='text-black pointer-events-none'>Menu</p>
      {open ? (
        <motion.div
          drag
          className='absolute'
          initial={{
            opacity: 0,
            transform: 'translate3d(0px, 60px, 0px)',
          }}
          animate={{
            opacity: 1,
            transform: 'translate3d(0px, 72px, 0px)',
          }}
          transition={{
            duration: 0.15,
          }}
        >
          <ul className='bg-white w-[150px] py-2 shadow-md rounded-md'>
            <li className='flex hover:bg-slate-50'>
              <Link
                to={personalTrainerProfileRoute}
                className='flex-1 px-2 py-1'
              >
                Perfil
              </Link>
            </li>
            <li
              className='hover:bg-slate-50 px-2 py-1'
              onClick={() => {
                signOut()
              }}
            >
              Sair
            </li>
          </ul>
        </motion.div>
      ) : null}
    </div>
  )
}
