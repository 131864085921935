import { forwardRef } from 'react'
import { BsCheck } from 'react-icons/bs'
import { ICheckboxProps } from './ICheckboxProps'

const component: React.ForwardRefRenderFunction<
  HTMLInputElement,
  ICheckboxProps
> = ({ label, error = false, ...props }, ref) => {
  return (
    <label className='flex focus-within:bg-gpa-gray-100 gap-3 cursor-pointer'>
      <div
        className={
          'relative z-0 flex shrink-0 w-[25px] h-[25px] border-2 rounded-md ' +
          (error ? 'border-gpa-red' : 'border-gpa-blue-500')
        }
      >
        <input
          {...props}
          ref={ref}
          type='checkbox'
          className='peer opacity-0'
        />
        <div className='absolute h-full w-full peer-checked:block hidden bg-gpa-blue-500'>
          <BsCheck className='text-white w-full h-full' />
        </div>
      </div>
      <span className='z-0'>{label}</span>
    </label>
  )
}

export const Checkbox = forwardRef(component)
