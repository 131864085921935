import zod from 'zod'

export const maritalStatusOptions = [
  'solteiro(a)',
  'casado(a)',
  'divorciado(a)',
  'separado(a)',
  'viúvo(a)',
] as const

export const genderOptions = ['masculino', 'feminino'] as const

export const schema = zod
  .object({
    name: zod.string().nonempty('Campo obrigatório').trim(),
    phoneNumber: zod
      .array(
        zod
          .string()
          .min(11, 'Deve ter no mínimo 11 números')
          .trim()
          .transform((value) => value.replace(/\D/g, '')),
      )
      .nonempty('Campo obrigatório'),
    password: zod
      .string()
      .trim()
      .optional()
      .transform((data) => (!data?.length ? undefined : data))
      .refine((data) => !(data?.length && data.length < 6), {
        message: 'Deve ter no mínimo 6 caracteres',
      }),
    confirm_password: zod
      .string()
      .trim()
      .nullish()
      .optional()
      .transform((data) => (!data?.length ? undefined : data))
      .refine((data) => !(data?.length && data.length < 6), {
        message: 'Deve ter no mínimo 6 caracteres',
      }),
    birthday: zod
      .string()
      .nonempty('Campo obrigatório')
      .length(10, 'Data incompleta')
      .trim()
      .transform((value) => value.replace(/\D/g, '')),
    documentNumber: zod
      .string()
      .nonempty('Campo obrigatório')
      .length(14, 'Número incompleto')
      .trim()
      .transform((value) => value.replace(/\D/g, '')),
    socialDocumentNumber: zod
      .string()
      .length(13, 'Número incompleto')
      .nonempty('Campo obrigatório')
      .trim()
      .transform((value) => value.replace(/\D/g, '')),
    email: zod.string().email('Deve ser um e-mail válido').trim(),
    maritalStatus: zod.enum(maritalStatusOptions, {
      required_error: 'Campo obrigatório',
    }),
    sex: zod.enum(genderOptions, {
      required_error: 'Campo obrigatório',
    }),
  })
  .strict()
  .refine((data) => data.password === data.confirm_password, {
    message: 'As senhas não estão iguais',
    path: ['confirm_password'],
  })
