import { api } from '@utils/api'
import { promiseResolver } from '@utils/promiseResolver'
import { AxiosError, AxiosResponse } from 'axios'

export async function POSTLogin(
  payload: Omit<LoginFormData, 'shouldRemember'>,
) {
  const [data] = await promiseResolver<
    AxiosResponse<AuthenticationDTO>,
    AxiosError
  >(api.post('/authenticate', payload))

  return data?.data
}
