import { Modal } from '@components/Modal'
import { useCallback, useState } from 'react'
import { useStudentCard } from '../useStudentCard'
import { Link } from 'react-router-dom'
import { studentEvaluationsRoute } from '@routes/routes'
import { FiX } from 'react-icons/fi'

export const EvaluationsList: React.FC = () => {
  const { student } = useStudentCard()
  const [showModal, setShowModal] = useState<boolean>(false)

  const handleToggleModal = useCallback(() => {
    setShowModal((state) => !state)
  }, [])

  return (
    <>
      <button
        className='bg-white hover:bg-gpa-gray-100 px-3 rounded-lg'
        onClick={handleToggleModal}
      >
        <span className='text-gpa-blue-500'>ver todas</span>
      </button>
      {showModal ? (
        <Modal>
          <div className='flex items-center justify-between mb-2'>
            <h2 className='text-xl font-medium truncate'>
              Avaliações - {student.name}
            </h2>
            <button
              className='bg-white hover:bg-gpa-gray-50 p-1.5 rounded-full'
              onClick={handleToggleModal}
            >
              <FiX size={24} />
            </button>
          </div>
          <div className='flex flex-col gap-2'>
            {student.studentEvaluations.map((evaluation) => (
              <Link
                key={evaluation.id}
                to={studentEvaluationsRoute
                  .replace(':student_id', student.id)
                  .replace(':evaluation_id', evaluation.id)}
                className='p-3 rounded-lg text-gpa-blue-500 hover:bg-gpa-gray-50 cursor-pointer'
                state={{ student }}
              >
                {new Date(evaluation.createdAt).toLocaleDateString()}
              </Link>
            ))}
          </div>
        </Modal>
      ) : null}
    </>
  )
}
