import { useCallback } from 'react'
import { PageContainer } from '@layout/PageContainer'
import bgImage from '@assets/img/loginBackground.png'
import { LoginForm } from './components/form'
import { useAuth } from '@hooks/useAuth'

export const Login: React.FC = () => {
  const { signIn } = useAuth()

  const handleSubmitForm = useCallback(
    async (payload: LoginFormData) => {
      signIn(payload)
    },
    [signIn],
  )

  return (
    <PageContainer>
      <div
        className='flex flex-1 bg-no-repeat bg-left-top bg-cover'
        style={{
          backgroundImage: `url(${bgImage})`,
          minHeight: window.innerHeight,
        }}
      >
        <div className='flex justify-center items-center w-full max-w-2xl bg-white ml-auto px-6'>
          <div className='flex flex-col flex-1 max-w-[500px] items-start gap-3'>
            <h1 className='text-4xl font-semibold'>Bem Vindo,</h1>
            <p className='text-xl'>Para acessar o GPA, faça o login abaixo.</p>
            <LoginForm onSubmit={handleSubmitForm} />
          </div>
        </div>
      </div>
    </PageContainer>
  )
}
