import { promiseResolver } from '@utils/promiseResolver'
import { IUpdatePersonalTrainerDTO } from './IUpdatePersonalTrainerDTO'
import { AxiosError, AxiosResponse } from 'axios'
import { api } from '@utils/api'

export async function PUTUpdatePersonalTrainer(
  data: IUpdatePersonalTrainerDTO,
): Promise<[User | undefined, APIError | undefined]> {
  const [response, error] = await promiseResolver<
    AxiosResponse<User>,
    AxiosError<APIError>
  >(api.put('/users', data))

  return [response?.data, error?.response?.data]
}
