import { useCallback, useEffect, useRef, useState } from 'react'
import { FiPlus } from 'react-icons/fi'
import { Button } from '@components/Button'
import GPALogo from '@assets/img/gpaLogo.png'
import { RegisterStudentModal } from './RegisterStudentModal'
import { DropDownMenu } from './components/dropDownMenu'
import { useLayout } from '@hooks/useLayout'

export const TopBar: React.FC = () => {
  const { hasScrolled } = useLayout()
  const navRef = useRef<HTMLDivElement>(null)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const handleToggleModal = useCallback(() => {
    setIsModalOpen((state) => !state)
  }, [])

  useEffect(() => {
    hasScrolled
      ? navRef.current?.classList.add('shadow-md')
      : navRef.current?.classList.remove('shadow-md')
  }, [hasScrolled])

  return (
    <nav
      ref={navRef}
      className='sticky top-0 flex justify-end gap-3 px-6 py-2.5 bg-white z-10'
    >
      <img src={GPALogo} alt='GPA' className='h-10 mr-auto' />
      <DropDownMenu />
      <Button onClick={handleToggleModal}>Novo Aluno</Button>
      <RegisterStudentModal onClose={handleToggleModal} isOpen={isModalOpen} />
    </nav>
  )
}
