import zod from 'zod'

export const schema = zod
  .object({
    documentNumber: zod
      .string()
      .min(11, 'Deve ter no mínimo 11 dígitos')
      .trim(),
    password: zod.string().min(6, 'Deve ter no mínimo 6 caracteres').trim(),
    shouldRemember: zod.boolean(),
  })
  .strict()
