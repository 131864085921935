import { Button } from '@components/Button'
import { Modal } from '@components/Modal'
import { FiX } from 'react-icons/fi'
import { RegisterStudentForm } from './components/registerStudentForm'
import { useCallback } from 'react'
import { ICreateStudentDTO } from '@services/api/students/ICreateStudentDTO'
import { POSTCreateStudent } from '@services/api/students'
import { useNavigate } from 'react-router-dom'
import { POSTCreateEvaluation } from '@services/api/students/evaluations'
import { studentEvaluationsRoute } from '@routes/routes'
import studentsList from '@observables/studentsList'

export const RegisterStudentModal: React.FC<RegisterStudentModalProps> = ({
  isOpen,
  onClose,
}) => {
  const navigate = useNavigate()

  const handleSubmit = useCallback(
    async (payload: ICreateStudentDTO) => {
      const [student] = await POSTCreateStudent(payload)

      if (student) {
        const [evaluation] = await POSTCreateEvaluation(student.id)

        studentsList.addStudentToList(student)
        onClose()

        if (evaluation)
          navigate(
            studentEvaluationsRoute
              .replace(':student_id', student.id)
              .replace(':evaluation_id', evaluation.id),
            { state: { student } },
          )
      }
    },
    [onClose, navigate],
  )

  return isOpen ? (
    <Modal>
      <div className='flex justify-between items-center mb-2'>
        <h2 className='text-xl font-medium'>Dados atuais do avaliado</h2>
        <button
          className='bg-white hover:bg-gpa-gray-50 p-1 rounded-full'
          onClick={onClose}
        >
          <FiX size={24} />
        </button>
      </div>
      <main className='flex flex-col gap-3'>
        <RegisterStudentForm onSubmit={handleSubmit} />
        <Button
          type='button'
          mode='outlined'
          className='w-full'
          onClick={onClose}
        >
          Cancelar
        </Button>
      </main>
    </Modal>
  ) : null
}
