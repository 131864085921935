/* 1st Question */
export const goalsEnum = [
  'Emagrecimento',
  'Hipertrofia muscular',
  'Estética',
  'Condicionamento físico',
  'Melhorar desempenho',
  'Indicação médica',
  'Lazer',
  'Saúde e prevenção',
] as const

/* 2nd Question */
export const presentPhysicalConditioningEnum = [
  'Inativo(a)',
  'Irregularmente ativo',
  'Ativo(a)',
  'Muito ativo',
  'Atleta',
] as const

/* 3rd Question */
export const periodEnum = ['Dia(s)', 'Semana(s)', 'Mês(es)', 'Ano(s)'] as const

/* 4th Question */
export const availableDaysPerWeekEnum = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
] as const

/* 5th Question */
export const availableTimeEnum = ['60', '90', '120', '+120 min'] as const

/* 6th Question */
export const availableWeekDaysEnum = [
  'Segunda',
  'Terça',
  'Quarta',
  'Quinta',
  'Sexta',
  'Sábado',
  'Domingo',
] as const

/* 7th Question */
export const exercisesEnum = [
  'Musculação',
  'Natação',
  'Dança',
  'Corrida',
  'Pilates',
  'Futebol',
  'Hidroginástica',
  'Caminhada',
  'Lutas',
  'Nenhum',
] as const

/* 8th Question */
export const exerciseIntensityLevelEnum = [
  'Baixa',
  'Moderada',
  'Alta',
  'Nenhuma',
] as const

/* 9th Question */
export const exercisesLocaleEnum = [
  'Em casa',
  'Academia',
  'Ao ar livre',
  'Em casa e na academia',
  'Em casa e ao ar livre',
  'Em casa, academia e ao ar livre',
] as const

/* 10th Question */
export const regularMealsEnum = [
  'Café',
  'Colação',
  'Almoço',
  'Lanche',
  'Jantar',
  'Ceia',
] as const

/* 11th Question */
export const dietEnum = [
  'Não',
  'Sim a menos de 3 meses',
  'Sim de 3 a 6 meses',
  'Sim de 6 meses a 1 ano',
  'Sim a mais de 1 ano',
] as const

/* 12th Question */
// Any string

/* 13th Question */
export const efforTestEnum = [
  'Não',
  'Sim há menos de 1 ano',
  'Sim há mais de 1 ano',
] as const

/* 14th Question */
export const alcoholicDrinksEnum = [
  'Nunca',
  'Uma vez por mês ou menos',
  '2-4 vezes por mês',
  '2-3 vezes por semana',
  '4 vezes ou mais por semana',
] as const

/* 15th Question */
export const litersPerDayEnum = [
  'Não',
  'Sim, de  4 a 8  copos de 250ml (1 a 2 litros) por dia',
  'Sim, de 8 a 12 copos de 250ml (2 a 3 litros) por dia',
  'Sim, acima de 12 copos de 250ml (acima 3 litros) por dia',
] as const

/* 16th Question */
export const diabetesEnum = [
  'Não e também não há casos na família',
  'Não, mas há casos na família',
  'Sim, mas estou em tratamento',
  'Sim , mas não está controlado',
] as const

/* 17th Question */
export const glicemyLevelEnum = [
  'Não conhece',
  'Inferior a 70 mg/dL',
  'Entre 70 e 99 mg/dL',
  'Superior a 99 mg/dL',
] as const

/* 18th Question */
export const cholesterolLevelsEnum = [
  'Não conhece',
  'inferior a 200 mg/dL',
  'de 200 e 239 mg/dL',
  'superior a 239 mg/dL',
] as const

/* 19th Question */
export const triglyceridesLevelsEnum = [
  'Não conhece',
  'Inferior a 150 mg/dL',
  'de 150 a 200 mg/dL',
  'de 201 a 499 mg/dL',
  'superior a 499 mg/dL',
] as const

/* 20th Question */
export const tabagismEnum = [
  'Nunca fumou',
  'Abandonou a 1 mês',
  'Abandonou a menos de 1 ano',
  'Abandonou a menos 5 anos',
  'Abandonou a menos 10 anos',
  'Fuma charuto ou cachimbo',
  'Fuma menos de 10 cigarros por dia',
  'Fuma entre 10-20 cigarros por dia',
  'Fuma entre 21-30 cigarros por dia',
  'Fuma mais de 30 cigarros por dia',
] as const

/* 21th Question */
export const diseasesEnum = [
  'Anemia',
  'Asma',
  'Hepatite',
  'Dores musculares',
  'Epilepsia',
  'Hipertensão',
  'Hipotensão',
  'Cardiopatia',
  'Varizes',
  'Diabetes',
  'Hipercolesterolemia',
  'Nenhuma',
] as const

/* 22th Question */
export const medicationEnum = [
  'Antidepressivo',
  'Anti Hipertensivo',
  'Analgésico',
  'Betabloqueador',
  'Broncodilatador',
  'Contraceptivo',
  'Calmante',
  'Insulina',
  'Nenhum',
] as const

/* 23th Question */
export const jointMuscularDamageEnum = [
  'Ruptura muscular',
  'Ruptura ligamentar',
  'Tendinite',
  'Fratura',
  'Entorse',
  'Cirurgia',
  'Hérnia de disco',
  'Lombalgia',
  'Nenhuma',
] as const

/* 24th Question */
export const pedigreeEnum = [
  'Anemia',
  'Asma',
  'Dores musculares',
  'Epilepsia',
  'Hipertensão',
  'Hipercolesterolemia',
  'Hipotensão',
  'Cardiopatia',
  'Varizes',
  'Diabetes',
  'Hepatite',
  'Nenhuma',
] as const

/* 25th Question */
// Any string
