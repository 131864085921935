import { api } from '@utils/api'
import { promiseResolver } from '@utils/promiseResolver'
import { AxiosError, AxiosResponse } from 'axios'

export async function GETAnamnesisRecord(
  studentId: string,
  evaluationId: string,
) {
  const [response] = await promiseResolver<
    AxiosResponse<Anamnesis>,
    AxiosError
  >(api.get(`/students/${studentId}/evaluations/${evaluationId}/anamneses`))

  return response?.data
}
