import {
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useState,
} from 'react'
import { FiEye, FiEyeOff } from 'react-icons/fi'
import { ITextInputProps } from './ITextInputProps'

const Input: ForwardRefRenderFunction<HTMLInputElement, ITextInputProps> = (
  { label, type = 'text', error, noError = false, rightIconArea, ...rest },
  ref,
) => {
  const [hidePassword, setHidePassword] = useState<boolean>(true)

  const handleHidePassword = useCallback(() => {
    setHidePassword(() => true)
  }, [])

  const handleShowPassword = useCallback(() => {
    setHidePassword(() => false)
  }, [])

  return (
    <div className='flex flex-col flex-1 gap-3 min-w-[244px]'>
      {label ? (
        <span className={`${error ? 'text-gpa-red' : ''}`}>{label}</span>
      ) : null}
      <label
        className={
          'flex flex-row px-4 border-500 focus-within:border-gpa-blue-500 py-2 h-[48px] border-2 rounded-md gap-2 ' +
          (error ? '!border-gpa-red' : '')
        }
      >
        <input
          {...rest}
          ref={ref}
          type={type === 'password' && !hidePassword ? 'text' : type}
          autoComplete='off'
          className='w-full outline-none bg-transparent autofill:!bg-transparent'
        />
        <div>{rightIconArea?.map((icon) => icon)}</div>
        {type === 'password' ? (
          <button
            type='button'
            className='select-none'
            onClick={hidePassword ? handleShowPassword : handleHidePassword}
          >
            {hidePassword ? (
              <FiEye
                size={23}
                className={'' + (error ? 'text-gpa-red' : 'text-gpa-gray-800')}
              />
            ) : (
              <FiEyeOff
                size={23}
                className={'' + (error ? 'text-gpa-red' : 'text-gpa-gray-800')}
              />
            )}
          </button>
        ) : null}
      </label>
      {noError ? null : (
        <span
          className={`flex text-xs text-gpa-red ${
            error ? 'visible' : 'invisible'
          }`}
        >
          {error}&nbsp;
        </span>
      )}
    </div>
  )
}

export const TextInput = forwardRef(Input)
