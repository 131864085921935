import { Button } from '@components/Button'
import { QuestionFrame } from '@components/QuestionFrame'
import { zodResolver } from '@hookform/resolvers/zod'
import { ICreateParQDTO } from '@services/api/students/evaluations/anamnesis/parq/ICreateParQDTO'
import { useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FiChevronRight } from 'react-icons/fi'
import { parqFormSchema } from './schemas'
import { useLayout } from '@hooks/useLayout'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { POSTParQForm } from '@services/api/students/evaluations/anamnesis/parq'
import { RadioButtonArray } from '@components/RadioButtonArray'

export const ParQForm: React.FC<ParQFormProps> = ({
  onSubmit,
  defaultValues,
}) => {
  const { scrollToTop } = useLayout()
  const navigate = useNavigate()
  const {
    state: { student },
  } = useLocation()
  const { evaluation_id, student_id } = useParams<EvaluationRouteParams>()
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<ICreateParQDTO>({
    defaultValues,
    resolver: zodResolver(parqFormSchema),
  })

  const [loading, setLoading] = useState<boolean>(false)

  const handleFormSubmit = useCallback(
    async (formData: ICreateParQDTO) => {
      if (defaultValues && parqFormSchema.safeParse(defaultValues)) {
        requestAnimationFrame(() => {
          scrollToTop()
          navigate('?subform=fatorDeRiscoCoronariano', {
            state: { student },
          })
        })
        return
      }

      if (student_id && evaluation_id) {
        setLoading(true)
        const [parQ, error] = await POSTParQForm(
          student_id,
          evaluation_id,
          formData as unknown as ParQ,
        )
        setLoading(false)

        if (parQ) {
          onSubmit(parQ)
          scrollToTop()
          navigate('?subform=fatorDeRiscoCoronariano', {
            state: { student },
          })
        } else if (error) {
          console.log(error.response?.data)
        }
      }
    },
    [
      scrollToTop,
      defaultValues,
      navigate,
      student,
      evaluation_id,
      student_id,
      onSubmit,
    ],
  )

  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      className='flex flex-col gap-6'
    >
      <QuestionFrame
        title='1) Algum médico já disse que você possui algum problema de coração e que só deveria realizar atividade física supervisionada por profissionais de saúde?'
        isValid={
          parqFormSchema.pick({ supervisedPhysicalActivity: true }).safeParse({
            supervisedPhysicalActivity: watch('supervisedPhysicalActivity'),
          }).success
        }
      >
        <Controller
          control={control}
          name='supervisedPhysicalActivity'
          render={({ field }) => (
            <RadioButtonArray
              value={field.value}
              onChange={field.onChange}
              options={[
                { label: 'Sim', value: true },
                { label: 'Não', value: false },
              ]}
            />
          )}
        />
        <span className='text-xs text-gpa-red'>
          {errors?.supervisedPhysicalActivity?.message}
        </span>
      </QuestionFrame>

      <QuestionFrame
        title='2) Você sente dores no peito quando pratica atividade física?'
        isValid={
          parqFormSchema.pick({ chestPain: true }).safeParse({
            chestPain: watch('chestPain'),
          }).success
        }
      >
        <Controller
          control={control}
          name='chestPain'
          render={({ field }) => (
            <RadioButtonArray
              value={field.value}
              onChange={field.onChange}
              options={[
                { label: 'Sim', value: true },
                { label: 'Não', value: false },
              ]}
            />
          )}
        />
        <span className='text-xs text-gpa-red'>
          {errors?.chestPain?.message}
        </span>
      </QuestionFrame>

      <QuestionFrame
        title='3) No último mês, você sentiu dores no peito quando praticava atividade física?'
        isValid={
          parqFormSchema.pick({ chestPainLastMonth: true }).safeParse({
            chestPainLastMonth: watch('chestPainLastMonth'),
          }).success
        }
      >
        <Controller
          control={control}
          name='chestPainLastMonth'
          render={({ field }) => (
            <RadioButtonArray
              value={field.value}
              onChange={field.onChange}
              options={[
                { label: 'Sim', value: true },
                { label: 'Não', value: false },
              ]}
            />
          )}
        />
        <span className='text-xs text-gpa-red'>
          {errors?.chestPainLastMonth?.message}
        </span>
      </QuestionFrame>

      <QuestionFrame
        title='4) Você apresenta desequilíbrio devido à tontura e/ou a perda de consciência?'
        isValid={
          parqFormSchema.pick({ dizziness: true }).safeParse({
            dizziness: watch('dizziness'),
          }).success
        }
      >
        <Controller
          control={control}
          name='dizziness'
          render={({ field }) => (
            <RadioButtonArray
              value={field.value}
              onChange={field.onChange}
              options={[
                { label: 'Sim', value: true },
                { label: 'Não', value: false },
              ]}
            />
          )}
        />
        <span className='text-xs text-gpa-red'>
          {errors?.dizziness?.message}
        </span>
      </QuestionFrame>

      <QuestionFrame
        title='5) Você possui algum problema ósseo ou articular que poderia ser piorado pela atividade física?'
        isValid={
          parqFormSchema.pick({ boneAche: true }).safeParse({
            boneAche: watch('boneAche'),
          }).success
        }
      >
        <Controller
          control={control}
          name='boneAche'
          render={({ field }) => (
            <RadioButtonArray
              value={field.value}
              onChange={field.onChange}
              options={[
                { label: 'Sim', value: true },
                { label: 'Não', value: false },
              ]}
            />
          )}
        />
        <span className='text-xs text-gpa-red'>
          {errors?.boneAche?.message}
        </span>
      </QuestionFrame>

      <QuestionFrame
        title='6) Você toma atualmente algum medicamento para pressão arterial e/ou problema de coração?'
        isValid={
          parqFormSchema.pick({ bloodPressureMedication: true }).safeParse({
            bloodPressureMedication: watch('bloodPressureMedication'),
          }).success
        }
      >
        <Controller
          control={control}
          name='bloodPressureMedication'
          render={({ field }) => (
            <RadioButtonArray
              value={field.value}
              onChange={field.onChange}
              options={[
                { label: 'Sim', value: true },
                { label: 'Não', value: false },
              ]}
            />
          )}
        />
        <span className='text-xs text-gpa-red'>
          {errors?.bloodPressureMedication?.message}
        </span>
      </QuestionFrame>

      <QuestionFrame
        title='7) Sabe de alguma razão pela qual você não deve realizar atividade física?'
        isValid={
          parqFormSchema.pick({ reasonNotToExercise: true }).safeParse({
            reasonNotToExercise: watch('reasonNotToExercise'),
          }).success
        }
      >
        <Controller
          control={control}
          name='reasonNotToExercise'
          render={({ field }) => (
            <RadioButtonArray
              value={field.value}
              onChange={field.onChange}
              options={[
                { label: 'Sim', value: true },
                { label: 'Não', value: false },
              ]}
            />
          )}
        />
        <span className='text-xs text-gpa-red'>
          {errors?.reasonNotToExercise?.message}
        </span>
      </QuestionFrame>

      <div className='flex justify-end'>
        <Button loading={loading}>
          Avançar <FiChevronRight size={24} />
        </Button>
      </div>
    </form>
  )
}
