import { useContext } from 'react'
import { authContext } from '@contexts/authContext'

export function useAuth() {
  const context = useContext<AuthContextData>(authContext)

  if (!Object.keys(context).length) {
    throw new Error('useAuth must be used within an AuhtProvider')
  }

  return context
}
