import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { SubnavItem } from '@components/Subnav'

import { GeneralForm } from './components/generalForm'
import { ParQForm } from './components/parqForm'
import { CoronarianRiskFactorForm } from './components/coronarianRiskFactorForm'
import { StressForm } from './components/stressForm'

import { generalFormSchema } from './components/generalForm/schema'
import { useStudentEvaluation } from '@hooks/useStudentEvaluation'
import { parqFormSchema } from './components/parqForm/schemas'
import { formList } from './utils/values'

export const AnamnesisForm: React.FC = () => {
  const { evaluation, updateEvaluation } = useStudentEvaluation()

  const {
    search,
    state: { student },
  } = useLocation()
  const query = useMemo(() => new URLSearchParams(search), [search])
  const navigate = useNavigate()

  const handleSubmitGeneralForm = useCallback(
    async (data: General) => {
      if (!evaluation?.anamnesis) return

      updateEvaluation({
        anamnesis: {
          ...evaluation.anamnesis,
          general: data,
        },
      })
    },
    [evaluation, updateEvaluation],
  )

  const handleSubmitParQForm = useCallback(
    async (data: ParQ) => {
      if (!evaluation?.anamnesis) return

      updateEvaluation({
        anamnesis: {
          ...evaluation.anamnesis,
          parQ: data,
        },
      })
    },
    [updateEvaluation, evaluation],
  )

  const getSubnavigationAvailability = useCallback(
    (formKey: AnamnesisFormKey) => {
      switch (formKey) {
        case 'geral':
          return true
        case 'parQ':
          return generalFormSchema.safeParse(evaluation?.anamnesis?.general)
            .success
        case 'fatorDeRiscoCoronariano':
          return parqFormSchema.safeParse(evaluation?.anamnesis?.parQ).success
        case 'stress':
          return false
        default:
          return false
      }
    },
    [evaluation],
  )

  const Form = useMemo(() => {
    switch (query.get('subform') as AnamnesisFormKey) {
      case 'fatorDeRiscoCoronariano':
        return <CoronarianRiskFactorForm />
      case 'parQ':
        return (
          <ParQForm
            defaultValues={evaluation?.anamnesis?.parQ}
            onSubmit={handleSubmitParQForm}
          />
        )
      case 'stress':
        return <StressForm />
      case 'geral':
      default:
        return (
          <GeneralForm
            defaultValues={evaluation?.anamnesis?.general}
            onSubmit={handleSubmitGeneralForm}
          />
        )
    }
  }, [query, evaluation, handleSubmitGeneralForm, handleSubmitParQForm])

  return (
    <div className='flex flex-col px-6 py-9 gap-6 rounded-2xl bg-white'>
      <nav className='flex overflow-x-auto'>
        {formList.map((item, index) => (
          <SubnavItem
            key={`${index}-subnav`}
            title={item.label}
            isActive={
              (query.get('subform') as AnamnesisFormKey)
                ? item.key === (query.get('subform') as AnamnesisFormKey)
                : item.key === 'geral'
            }
            disabled={!getSubnavigationAvailability(item.key)}
            onClick={() =>
              navigate('?subform=' + item.key, {
                replace: true,
                state: { student },
              })
            }
          />
        ))}
      </nav>

      {Form}
    </div>
  )
}
