import { FiChevronRight } from 'react-icons/fi'

export const FormStepIndicator: React.FC<FormStepIndicatorProps> = ({
  title,
  step,
  seen = false,
  last = false,
}) => {
  return (
    <div className='flex shrink-0 gap-1.5 items-center cursor-pointer'>
      <div
        className={
          (seen ? 'bg-gpa-blue-500' : 'bg-gpa-gray-800') +
          ' flex shrink-0 justify-center items-center h-[30px] w-[30px] rounded-full py-2.5 px-1'
        }
      >
        <span className='font-semibold text-white'>{step}</span>
      </div>
      <p className='whitespace-nowrap'>{title}</p>
      {last ? null : (
        <div>
          <FiChevronRight className='text-gpa-gray-800' />
        </div>
      )}
    </div>
  )
}
